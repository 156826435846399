import React, { useState } from "react";
import { uploadRecordingVideo } from "../../redux/actions/manageVideos";
import { connect, useDispatch } from "react-redux";
import {
  checkLengthOfUpload,
  failureMessagePopup,
  getVideoDuration,
  sg_HmsToseconds,
  successMessagePopup,
} from "../../helper/helper";
import { useNavigate } from "react-router-dom";
import { localStoragePrefix } from "../../utils/config";
import loaderIcon from "../../assets/images/light-loader.gif";

const UploadVideo = ({ userDetails }) => {
  const userID = localStorage.getItem(localStoragePrefix + "screenGeniusUserID");
  const accessToken = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");
  const [loading, setLoading] = useState(false);
  const userDetailsObj = userDetails?.data && userDetails?.data;
  const userPricingPlan = userDetailsObj?.pricing_plan && userDetailsObj?.pricing_plan;
  const planFeatures = userPricingPlan?.features && JSON.parse(userPricingPlan?.features);
  const allowUploadVideo = planFeatures?.Unlimited?.videos && Number(planFeatures?.Unlimited?.videos);
  const uploadedVideoLength = userDetailsObj?.videos && userDetailsObj?.videos;
  const uploadedVideoSize = planFeatures?.UnlimitedLength?.length && Number(planFeatures?.UnlimitedLength?.length);

  const dispatch = useDispatch();
  const navigator = useNavigate();
  const handleUploadVideo = (e) => {
    setLoading(true);
    const file = e?.target.files[0];
    const fileName = file?.name;
    const fileType = file?.type;
    const fileSize = file?.size;
    var payload = {
      name: fileName,
      user_id: userID,
      file_size: fileSize,
      file: file,
      access_token: accessToken,
    };
    if (fileType?.indexOf("video") > -1) {
      function isNotWebmOrAvi(filename) {
        return !/(?:\.webm$|\.avi$)/i.test(filename);
      }
      checkLengthOfUpload(uploadedVideoLength, allowUploadVideo, (res) => {
        if (res === true) {
          // uploadedVideoSize
          getVideoDuration(payload?.file, (res) => {
            checkLengthOfUpload(sg_HmsToseconds(res), Number(uploadedVideoSize), (res) => {
              if (res === true) {
                const dispatchUpload = (payload) => {
                  dispatch(
                    uploadRecordingVideo(payload, (type, res) => {
                      if (type === "success") {
                        let videoId = res?.data?.insertedId && res?.data?.insertedId;
                        successMessagePopup(
                          "Uploaded Successfully",
                          "Video uploaded successfully!"
                        );
                        setTimeout(() => {
                          navigator(`/edit-video/${videoId}`);
                        }, 1000);
                        setLoading(false);
                      } else if (type === "fail") {
                        let message = res?.message;
                        if (message?.includes('You exceeded Limit')) {
                          failureMessagePopup("Limit Reached", "You have reached max upload limit.");
                        } else {
                          failureMessagePopup("Error Message", res?.message);
                        }
                        setLoading(false);
                      }
                    })
                  );
                };
                if (isNotWebmOrAvi(fileName)) {
                  getVideoDuration(file, (res) => {
                    payload.file_duration = res;
                    dispatchUpload(payload);
                  });
                } else {
                  payload.file_duration = null;
                  dispatchUpload(payload);
                }
              } else {
                failureMessagePopup("Error message", `Your video is too long. The maximum allowed length is 5 minutes`);
                setLoading(false);
              }
            });
          })
        } else {
          failureMessagePopup("Limit Reached", "You have reached max upload limit.");
          setLoading(false);
        }
      });
    } else {
      failureMessagePopup(
        "Invalid format",
        "Only MP4, WEBM, AVI, MOV files allowed."
      );
      setLoading(false);
    }
  };
  return (
    <div id="quix-dashboard-content">
      <div id="quix-upload-video">
        <div id="upload-custom-video-outer" style={{ display: "table" }}>
          {loading && (
            <div className="upload-loader">
              <img className="video-loader-icon" src={loaderIcon} />
            </div>
          )}
          <span className="upload-custom-video-area">
            Drop or Choose your Video
          </span>
          <input
            id="upload-custom-video"
            type="file"
            accept=".mp4,.webm,.avi,.mov"
            onChange={(e) => handleUploadVideo(e)}
            disabled={loading}
            value={''}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state?.auth?.user_details
})

export default connect(mapStateToProps, {})(UploadVideo);
