// open new tab items
import { jsPDF } from "jspdf";
import jQuery from "jquery";
import "jquery-ui";
import successImg from "../assets/images/quix-success.png";
import failureImg from "../assets/images/quix-failure.png";
export const sgOpenNewTab = (path) => {
  window.open(path, "_blank");
};

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};
// Get image's base64 data from an image Url
function getImageFromUrl(url, name, callback) {
  var img = new Image();
  img.onError = function () {
    alert('Cannot load image: "' + url + '"');
  };
  img.onload = function () {
    callback(img, name);
  };
  img.src = url;
}
// To create PDF for download operation
function createPDF(imgData, name) {
  var doc = new jsPDF();
  var ww = imgData.width;
  var hh = imgData.height;
  var ar = ww / hh;
  var pdfW = parseInt(doc.internal.pageSize.width - 20);
  var pdfH = parseInt(pdfW / ar);
  var position = 0;
  var heightLeft = pdfH;
  var pageHeight = parseInt(doc.internal.pageSize.height);
  doc.addImage(imgData, "JPEG", 10, position, pdfW, pdfH); // Cache the image using the alias 'monkey'/
  heightLeft -= pageHeight;
  while (heightLeft >= 0) {
    position += pageHeight; // top padding for other pages
    doc.addPage();
    doc.addImage(imgData, "JPEG", 10, -Math.abs(position), pdfW, pdfH);
    heightLeft -= pageHeight;
  }

  var fileName = name + ".pdf";
  var source = doc.output("datauristring");
  var el = document.createElement("a");
  el.setAttribute("href", source);
  el.setAttribute("download", fileName);
  document.body.appendChild(el);
  try {
    el.click();
    // alert("Download Complete", "Recording is downloaded into downloads.");
  } catch (err) {
    alert(
      err +
      "Please try to download in full screen mode as host page is retricting the pdf download."
    );
  }
  el.remove();
}
// donwload items
export const sg_DownloadItem = async (
  type,
  path,
  itemName,
  itemId,
  downloadType, callback
) => {
  var namearr = itemName?.split(".");
  var name = itemName && itemName.replace(/\.[^/.]+$/, '');
  if (type == "video") {
    if (downloadType === "webm") {
      try {
        const response = await fetch(path);
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${name}.webm`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
        successMessagePopup('Download Complete', 'Recording is downloaded into downloads.')
        callback('success', 'Download Complete')
      } catch (error) {
        failureMessagePopup('Error Message', 'Some thing went wrong!');
        callback('failed', 'Download Filed');
      }
    }
    if (downloadType === "mp4") {
      try {
        const response = await fetch(path);
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${name}.mp4`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
        successMessagePopup('Download Complete', 'Recording is downloaded into downloads.')
        callback('success', 'Download Complete')
      } catch (error) {
        console.log(error, '-error--');
        failureMessagePopup('Error Message', 'Some thing went wrong!');
        callback('failed', 'Download Filed');
      }
    }
  } else {
    getBase64FromUrl(path).then(function (source) {
      if (downloadType == "png" || downloadType == "jpg") {
        var fileName = name + ".png";
        if (downloadType == "jpg") {
          fileName = name + ".jpg";
        }
        var el = document.createElement("a");
        el.setAttribute("href", source);
        el.setAttribute("download", fileName);
        document.body.appendChild(el);
        try {
          el.click();
          // alert("Download Complete", "Recording is downloaded into downloads.");
        } catch (err) {
          alert(
            err +
            "Please try to download in full screen mode as host page is retricting the pdf download."
          );
        }
        el.remove();
      } else if (downloadType == "pdf") {
        var base64EncodedPDF = source.split(",")[1];
        var decodedPdfContent = atob(base64EncodedPDF);
        var byteArray = new Uint8Array(decodedPdfContent.length);
        for (var i = 0; i < decodedPdfContent.length; i++) {
          byteArray[i] = decodedPdfContent.charCodeAt(i);
        }
        var blob = new Blob([byteArray.buffer], { type: "image/jpeg" });
        var source = URL.createObjectURL(blob);
        getImageFromUrl(source, name, createPDF);
      }
    });
  }
};
// downlaod transcript

export const sg_download_transcript = (text) => {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", "transcript.txt");
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

// share link
export const sg_share_link = (path) => {
  navigator.clipboard.writeText(path);
};

// multi select value
export const sg_handle_multiselect = (id, selectItemObject) => {
  selectItemObject((prevSelectedIDs) => {
    return prevSelectedIDs.includes(id)
      ? prevSelectedIDs.filter((selectedID) => selectedID !== id)
      : [...prevSelectedIDs, id];
  });
};

// formate file size
export const sg_format_bytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// profile progress
export const sq_profile_progress = (screenShots, videos, limitOfscreenshot, limitOfVideo) => {
  let videosLimit = limitOfVideo === -1 ? videos === 0 ? 1 : videos * 2 : limitOfVideo;
  let imagesLimit = limitOfscreenshot === -1 ? screenShots === 0 ? 1 : screenShots * 2 : limitOfscreenshot;
  let videosCount = videos;
  let screenshotCount = screenShots;
  var screenshotProgress = parseInt((screenshotCount / imagesLimit) * 100);
  let videoProgress = parseInt((videosCount / videosLimit) * 100);
  return {
    screenshot_progress: screenshotProgress,
    videos_progress: videoProgress,
  };
};

// seconds to HMs convert
export const sg_secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);
  var hDisplay = h > 0 ? (h < 10 ? "0" : "") + h : "00";
  var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m : "00";
  var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s : "00";
  return mDisplay + ":" + sDisplay;
};

// HMs to seconds convert
export const sg_HmsToseconds = (hms) => {
  if (!hms) return;
  var a = hms.split(":");
  var seconds = +a[0] * 60 + +a[1];
  return seconds;
};

export const sg_interval_HmsToseconds = (hms) => {
  if (!hms) return 0;
  const a = hms.split(":").map(Number);
  if (a.length !== 3) return 0;

  const seconds = a[0] * 3600 + a[1] * 60 + a[2];
  return seconds;
};

export function calculateIntervals(timeSlots) {
  let intervals = [];
  if (timeSlots.length > 0 && timeSlots[0].start !== "00:00:00") {
    intervals.push({
      start: "00:00:00",
      end: timeSlots[0].start,
      duration: getTimeDifference("00:00:00", timeSlots[0].start)
    });
  }

  for (let i = 0; i < timeSlots.length - 1; i++) {
    let endCurrent = timeSlots[i].end;
    let startNext = timeSlots[i + 1].start;

    intervals.push({
      start: endCurrent,
      end: startNext,
      duration: getTimeDifference(endCurrent, startNext)
    });
  }

  return intervals;
}

function getTimeDifference(start, end) {
  let startSeconds = timeToSeconds(start);
  let endSeconds = timeToSeconds(end);
  return endSeconds - startSeconds;
}

function timeToSeconds(time) {
  let [hh, mm, ss] = time.split(":").map(Number);
  return hh * 3600 + mm * 60 + ss;  // Convert to total seconds
}

//Get Video Duration
export const getVideoDuration = (fileData, callback) => {
  const file = fileData;
  const video = document.createElement("video");
  const objectURL = URL.createObjectURL(file);
  video.addEventListener("loadedmetadata", function () {
    const duration = video.duration;
    const minutes = Math.floor(duration / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(duration % 60)
      .toString()
      .padStart(2, "0");
    const durationFormat = minutes + ":" + seconds;
    if (!durationFormat || durationFormat === "Infinity:NaN") {
      callback(null);
    } else {
      callback(durationFormat);
    }
  });
  video.src = objectURL;
};

// Calculate End Date of Membership
export const calculateEndDate = (monthsToAdd) => {
  let currentDate = new Date();

  // Calculate the new month and year after adding 3 months
  let newMonth = currentDate.getMonth() + monthsToAdd;
  let newYear = currentDate.getFullYear() + Math.floor(newMonth / 12);

  // Adjust the new month to be within the range [0, 11]
  newMonth = newMonth % 12;

  // Get the current day of the month
  let currentDay = currentDate.getDate();

  // Calculate the last day of the new month
  let lastDayOfMonth = new Date(newYear, newMonth + 1, 0).getDate();

  // Determine the day for the end date (considering the current day)
  let endDay = Math.min(currentDay - 1, lastDayOfMonth);

  // Create a new Date object with the calculated month, year, and day
  let endDate = new Date(newYear, newMonth, endDay);

  return endDate;
};

// success message popup
export const successMessagePopup = (text, description, closeTime) => {
  let isClosetime = !closeTime ? 3000 : closeTime;

  let padTop = "40px";
  let html =
    '<div id="success-popup-wrapper">\n\
    <div class="success-popup">\n\
    <div class="success-heading" style="padding-top: ' +
    padTop +
    ';">\n\
    <img src="' +
    successImg +
    '"/>\n\
    <span class="success-message">' +
    text +
    '</span>\n\
    <span class="success-description">' +
    description +
    "</span>\n\
    </div>\n\
    </div>\n\
    </div>";
  jQuery("body").append(html);
  setTimeout(() => {
    jQuery("#success-popup-wrapper").remove();
  }, isClosetime);
};

// failure message popup
export const failureMessagePopup = (text, description, closeTime) => {
  let padTop = "30px";
  let isClosetime = !closeTime ? 5000 : closeTime;
  let html =
    '<div id="failure-popup-wrapper">\n\
        <div class="failure-popup">\n\
        <div class="failure-heading" style="padding-top: ' +
    padTop +
    ';">\n\
        <img src="' +
    failureImg +
    '"/>\n\
        <span class="failure-message">' +
    text +
    '</span>\n\
        <span class="failure-description">' +
    description +
    "</span>\n\
        </div>\n\
        </div>\n\
        </div>";
  jQuery("body").append(html);
  setTimeout(() => {
    jQuery("#failure-popup-wrapper").remove();
  }, isClosetime);
};

// share files on media
export const shareOnMedia = async (path) => {
  try {
    if (path) {
      const urlToBlob = async (path) => await (await fetch(path)).blob();
      try {
        const blob = await urlToBlob(path);
        const file = new File([blob], "share-img.png", { type: blob.type });
        await navigator.share({
          title: "Capios",
          text: "Screenshot & Video capture tool",
          files: [file],
          url: path,
        });
      } catch (error) {
        console.error("Error sharing:", error);
        failureMessagePopup('Error Message!', "This featured is not supported by this system.");
      }
    }
  } catch (error) {
    failureMessagePopup('Error Message!', "This featured is not supported by this system.");
    console.error("Something went wrong!", error);
  }
};

// verify url and replace with server url
export const checkServerURL = (serverURL, fileURL) => {
  if (!fileURL || fileURL === 'null') return null;
  if (fileURL) {
    let localFileURL = fileURL.replace(/^https?:\/\/[^\/]+/, "");
    let fullLocalURL = "";
    if (fileURL.includes("https")) {
      fullLocalURL = fileURL;
    } else {
      fullLocalURL = serverURL + localFileURL;
    }
    return fullLocalURL;
  }
};

// capitalize text
export const CapitalizeText = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

// ditect redirection url
export const redirectURL = () => {
  const hasPreviousPage = window.history;
  const previousPath = hasPreviousPage?.state?.usr?.redirectTo?.pathname;
  const currentPath = window.location.pathname;
  let pathUrl = !previousPath ? currentPath : previousPath;
  if (pathUrl.includes("/login") && !pathUrl.includes("/admin/login")) {
    pathUrl = "/dashboard";
  }
  return pathUrl;
};

// get file extentsion name
export const getFileExtension = (fileName) => {
  let name = fileName?.split(".");
  let extName = name?.[name?.length - 1]?.toUpperCase();
  return extName;
};

// check empty value
export const checkEmpty = (value) => {
  const isEmpty = (val) => {
    const str = String(val).trim().toLowerCase();
    return str === "undefined" || str === "null" || str === "";
  };

  if (typeof value === "string") {
    if (value.includes(",")) {
      return value
        .split(",")
        .map((part) => part.trim())
        .filter((part) => !isEmpty(part))
        .join(", ");
    } else {
      return isEmpty(value) ? "" : value;
    }
  } else if (Array.isArray(value)) {
    return value.filter((item) => !isEmpty(item));
  } else {
    return isEmpty(value) ? "" : value;
  }
};

// check upload items allowed length
export const checkLengthOfUpload = (uploaded, uploadAllow, callback) => {
  if (uploadAllow === -1) { return callback(true) } else {
    if (uploaded < uploadAllow) {
      return callback(true);
    } else {
      return callback(false);
    }
  }
}

// check null value
export const checkNullValue = (value) => {
  if (value === 'undefined' || value === undefined || value === null || value === 'null' || value === 'NULL') {
    return 'N/A'
  } else {
    return value;
  }
}

// check available path 
export const checkAvailableVideoPath = (path, mp4Path, modifiedPath) => {
  const isValid = (value) => value !== null && value !== "null" && value !== "";
  if (isValid(modifiedPath)) {
    return modifiedPath;
  }
  if (isValid(mp4Path)) {
    return mp4Path;
  }
  return path;
}