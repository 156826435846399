import React from "react";
import loaderIcon from "../../assets/images/light-loader.gif";

const LoadingPage = ({ opacity }) => {
    return (
        <div className="quix-loading-wrapper" style={{ opacity: opacity }}>
            <div className="quix-loading-page">
                <div className="quix-loading-spinner">
                    <img src={loaderIcon} alt="loading..." />
                </div>
            </div>
        </div>
    );
};

LoadingPage.defaultProps = {
    opacity: '0.9',
};

export default LoadingPage;
