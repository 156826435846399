import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/images/quix-close.png";
import uploaderIcon from "../../assets/images/upload-icon.png";
import {
  manageScreenShots,
  uploadScreenShot,
} from "../../redux/actions/manageScreenshots";
import { connect, useDispatch } from "react-redux";
import {
  checkLengthOfUpload,
  failureMessagePopup,
  getVideoDuration,
  sg_HmsToseconds,
  sq_profile_progress,
  successMessagePopup,
} from "../../helper/helper";
import {
  manageVideos,
  uploadRecordingVideo,
} from "../../redux/actions/manageVideos";
import { getUserDetailsByID, manageUserProgress } from "../../redux/actions/authentication";
import Multiselect from "multiselect-react-dropdown";
import { addTags, getAllTags } from "../../redux/actions/manageTags";
import { localStoragePrefix } from "../../utils/config";
import LoadingPage from "../../views/loading-page/LoadingPage";

const FileUploader = ({ showModal, closeModal, userID, progressDetails, allTags, userDetails, uploadType }) => {
  const imageFileFormat = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/JPG",
    "image/JPEG",
    "image/PNG",
    "image/GIF",
  ];
  const videoFileFormat = [
    "video/mp4",
    "video/webm",
    "video/avi",
    "video/mov",
    "video/quicktime",
    "video/MP4",
    "video/WEBM",
    "video/AVI",
    "video/MOV",
    "video/QUICKTIME",
  ];
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");

  const [formDetails, setFormDetails] = useState({
    title: "",
    summary: "",
    fileData: null,
  });

  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [tags, setTags] = useState('');
  const [selectTags, setSelectTags] = useState([]);
  // ***************** All tag list ****************
  let tagsList = allTags?.data && allTags?.data;

  useEffect(() => {
    if (userID && accessToken) {
      dispatch(getAllTags(Number(userID), accessToken));
    }
  }, [userID, accessToken]);

  // ***************** tags option list ****************
  const tagsOptions = tagsList && tagsList?.map((item) => {
    return {
      key: item?.id,
      cat: item?.name
    }
  });

  // ***************** tags selected option ****************
  const selectedTagsOptions = selectTags?.map((item) => {
    return {
      key: item.key,
      cat: item.cat,
    };
  });
  // ***************** file upload in change ****************

  const handleChangeUpload = (event, uploadType) => {
    const file = event?.target?.files?.[0];
    const fileName = file?.name;
    const fileType = file?.type;
    const fileSize = file?.size;
    setUploadedFileName(fileName);
    var payload = {
      name: fileName,
      file_size: fileSize,
      file: file,
      fileType: fileType,
    };

    if (uploadType === 'image') {
      if (imageFileFormat.indexOf(fileType) > -1) {
        // ***************** image upload ****************
        setFormDetails({ ...formDetails, fileData: payload });
      } else {
        closeModal(false);
        failureMessagePopup(
          "Invalid format",
          "Only JPG, JPEG, PNG or GIF files allowed."
        );
      }
    } else if (uploadType === 'video') {
      if (videoFileFormat.indexOf(fileType) > -1) {
        // ***************** recording upload ****************
        function isNotWebmOrAvi(filename) {
          return !/(?:\.webm$|\.avi$)/i.test(filename);
        }
        // ***************** check video format ****************
        if (isNotWebmOrAvi(fileName)) {
          // ***************** get video durations ****************
          getVideoDuration(file, (res) => {
            if (!res) {
              payload.file_duration = "null";
              setFormDetails({ ...formDetails, fileData: payload });
            } else {
              payload.file_duration = res;
              setFormDetails({ ...formDetails, fileData: payload });
            }
          });
        } else {
          payload.file_duration = "null";
          setFormDetails({ ...formDetails, fileData: payload });
        }
      } else {
        closeModal(false);
        failureMessagePopup(
          "Invalid format",
          "Only MP4, WEBM, AVI or MOV files allowed."
        );
      }
    }
  };

  useEffect(() => {
    let { screenshots, videos } = progressDetails;
    sq_profile_progress(screenshots, videos);
  }, [progressDetails]);

  // ***************** handle submit for upload screenshots and videos ****************
  const [loading, setLoading] = useState(false);
  const userDetailsObj = userDetails?.data && userDetails?.data;
  const userPricingPlan = userDetailsObj?.pricing_plan && userDetailsObj?.pricing_plan;
  const planFeatures = userPricingPlan?.features && JSON.parse(userPricingPlan?.features);
  const uploadedScreenshotLength = userDetailsObj?.screenshots && userDetailsObj?.screenshots;
  const allowUploadScreenshot = planFeatures?.Unlimited?.screenshots && Number(planFeatures?.Unlimited?.screenshots);
  const allowUploadVideo = planFeatures?.Unlimited?.videos && Number(planFeatures?.Unlimited?.videos);
  const uploadedVideoLength = userDetailsObj?.videos && userDetailsObj?.videos;
  const uploadedVideoSize = planFeatures?.UnlimitedLength?.length && Number(planFeatures?.UnlimitedLength?.length);
  const handleSubmit = () => {
    // ***************** payload for submission ****************
    setLoading(true);
    let payloadObj = {
      name: formDetails?.fileData?.name.replace(/ /g, "_"),
      user_id: Number(userID),
      access_token: accessToken,
      file_size: formDetails?.fileData?.file_size,
      file: formDetails?.fileData?.file,
      title: formDetails?.title,
      summary: formDetails?.summary,
      tags: selectTags?.map((el) => el.key),
    };
    if (!payloadObj?.name) {
      failureMessagePopup('Error Message', 'Please choose any file!')
      setLoading(false);
      return;
    }
    if (formDetails?.fileData?.fileType?.indexOf("image") > -1) {
      // ***************** upload screenshots ****************
      
      checkLengthOfUpload(uploadedScreenshotLength, allowUploadScreenshot, (res) => {
        if (res === true) {
          dispatch(
            uploadScreenShot(payloadObj, (type, res) => {
              if (type === "success") {
                successMessagePopup(
                  "Saved Successfuly",
                  "Your file is uploaded Successfuly."
                );
                setLoading(false);
                setTimeout(function () {
                  // ***************** manage screenshots list update after uploading screenshots ****************
                  dispatch(manageScreenShots(userID, accessToken));
                  // ***************** user progress update after uploading screenshots ****************
                  dispatch(manageUserProgress(userID, accessToken, function () { }));
                  dispatch(getUserDetailsByID(accessToken, function () { }));
                  closeModal(false);
                }, 1200);
              } else if (type === "fail") {
                closeModal(false);
                setLoading(false);
                let message = res?.message;
                if (message?.includes('You exceeded Limit')) {
                  failureMessagePopup("Limit Reached", "You have reached max upload limit.");
                } else {
                  failureMessagePopup("Error Message", res?.message);
                }
              }
            })
          );
        } else {
          failureMessagePopup("Limit Reached", "You have reached max upload limit.");
          closeModal(false);
          setLoading(false);
        }
      })
    }
    if (formDetails?.fileData?.fileType?.indexOf("video") > -1) {
      // ***************** upload vides ****************
      const videoPayloadObj = {
        ...payloadObj,
        file_duration: formDetails?.fileData?.file_duration
      }
      checkLengthOfUpload(uploadedVideoLength, allowUploadVideo, (res) => {
        if (res === true) {
          if(videoPayloadObj?.file_duration === 'null'){
            dispatch(
              uploadRecordingVideo(videoPayloadObj, (type, res) => {
                if (type === "success") {
                  successMessagePopup(
                    "Saved Successfuly",
                    "Your file is uploaded Successfuly."
                  );
                  setLoading(false);
                  setTimeout(function () {
                    closeModal(false);
                    // ***************** manage videos list update after uploading recording ****************
                    dispatch(manageVideos(userID, accessToken));
                    dispatch(getUserDetailsByID(accessToken, function () { }));
                    // ***************** user progress update after uploading recording ****************
                    dispatch(manageUserProgress(userID, accessToken, function () { }));
                  }, 1200);
                } else if (type === "fail") {
                  closeModal(false);
                  setLoading(false);
                  let message = res?.message;
                  if (message?.includes('You exceeded Limit')) {
                    failureMessagePopup("Limit Reached", "You have reached max upload limit.");
                  } else {
                    failureMessagePopup("Error Message", res?.message);
                  }
                }
              })
            );
          }else {
            // uploadedVideoSize
            checkLengthOfUpload(sg_HmsToseconds(videoPayloadObj?.file_duration), Number(uploadedVideoSize), (res) => {
              if (res === true) {
                dispatch(
                  uploadRecordingVideo(videoPayloadObj, (type, res) => {
                    if (type === "success") {
                      successMessagePopup(
                        "Saved Successfuly",
                        "Your file is uploaded Successfuly."
                      );
                      setLoading(false);
                      setTimeout(function () {
                        closeModal(false);
                        // ***************** manage videos list update after uploading recording ****************
                        dispatch(manageVideos(userID, accessToken));
                        dispatch(getUserDetailsByID(accessToken, function () { }));
                        // ***************** user progress update after uploading recording ****************
                        dispatch(manageUserProgress(userID, accessToken, function () { }));
                      }, 1200);
                    } else if (type === "fail") {
                      closeModal(false);
                      setLoading(false);
                      let message = res?.message;
                      if (message?.includes('You exceeded Limit')) {
                        failureMessagePopup("Limit Reached", "You have reached max upload limit.");
                      } else {
                        failureMessagePopup("Error Message", res?.message);
                      }
                    }
                  })
                );
              }else {
                failureMessagePopup("Error message", `Your video is too long. The maximum allowed length is 5 minutes`);
                closeModal(false);
                setLoading(false);
              }
            });
          }
        }else {
          failureMessagePopup("Limit Reached", "You have reached max upload limit.");
          closeModal(false);
          setLoading(false);
        }
      })
    }
  };


  // ***************** handle add custom tags ****************
  const [tagLoading, setTagLoading] = useState(false);
  const handleAddCustom = () => {
    const payload = {
      user_id: Number(userID),
      access_token: accessToken,
      name: tags
    }
    // ***************** add custom tags ****************
    if (tags?.trim('') === '') return failureMessagePopup('Error Message', "Tag cannot be empty!");
    dispatch(addTags(payload, (type, res) => {
      setTagLoading(true);
      if (type === 'success') {
        const lastTagId = res?.data?.id
        const lastTagName = res?.data?.name
        setSelectTags([...selectTags, { key: lastTagId, cat: lastTagName }])
        // ***************** get tags list ****************
        dispatch(getAllTags(userID, accessToken));
        // ***************** remove default value of tags ****************
        setTags('');
        setTagLoading(false);
      }
      else if (type === 'failed') {
        setTagLoading(false);
        failureMessagePopup('Error Message', res?.message);
      }
    }))
  }

  return (
    <div id="quix-file-uploader">
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !showModal ? "none" : "block",
        }}
      ></div>
      {loading && (<LoadingPage opacity={'0.9'} />)}
      <div id="quix-dashboard-popup">
        <div className="quix-dashboard-popup-inner">
          <div className="quix-dashboard-popup-title">
            <div className="quix-dashboard-popup-title-inner">
              <span>Upload File</span>
            </div>
          </div>
          <div className="quix-dashboard-popup-body">
            <div className="quix-dashboard-popup-body-inner">
              <div className="quix-upload-outer text-center">
                <div className="col-md-12 mb-3 text-start">
                  <div className="quix-item-uploader">
                    <input
                      id="input-file-upload"
                      className="quix-upload-input"
                      accept={uploadType === 'quix-tab-images' ? imageFileFormat.join(",") : videoFileFormat.join(",")}
                      type="file"
                      name="input-file-upload"
                      onChange={(e) => handleChangeUpload(e, uploadType === 'quix-tab-images' ? "image" : "video")}
                    />
                    <img src={uploaderIcon} />
                  </div>
                  <span style={{ fontSize: "14px" }}>{uploadedFileName}</span>
                </div>
                <div className="col-md-12 mb-1 text-start">
                  <label className="col-form-label">Title</label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    onChange={(e) =>
                      setFormDetails({ ...formDetails, title: e.target.value })
                    }
                    maxLength={255}
                  ></input>
                </div>
                <div className="col-md-12 mb-1 text-start">
                  <label className="col-form-label">Summary</label>
                  <textarea
                    type="text"
                    name="summary"
                    className="form-control"
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        summary: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                <div className="col-md-12 mb-1 text-start edit-tag-outer">
                  <label className="col-form-label">Tags</label>
                  <div className="col-md-12 mb-1 text-start tags-item-outer">
                    <Multiselect
                      name="tags"
                      id="tags"
                      isObject={true}
                      onSearch={function noRefCheck() { }}
                      options={tagsOptions}
                      displayValue={"cat"}
                      onSelect={(val) => setSelectTags(val)}
                      placeholder="select tags"
                      selectedValues={selectedTagsOptions}
                      defaultValue={selectedTagsOptions}
                      onRemove={(val) => setSelectTags(val)}
                    />
                  </div>
                  <div className="col-md-12 mb-1 text-start">
                    <label className="col-form-label">Add Custom Tag</label>
                    <div className='quix-add-cutom-tags'>
                      <input
                        type="text"
                        name="tags"
                        className="form-control"
                        placeholder="add custom tag"
                        onChange={(e) => setTags(e?.target?.value)}
                        value={tags}
                        maxLength={255}
                      ></input>
                      <button onClick={() => handleAddCustom()} disabled={tagLoading} className='btn add-custom-tag-btn'>Add custom</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4 upload-btn-outer">
                  <button
                    onClick={() => closeModal(false)}
                    className="btn form-action-button form-cancel-button"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleSubmit()}
                    className="btn form-action-button form-save-button position-relative"
                    disabled={loading}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="quix-dashboard-close"
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} alt="close" />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allTags: state?.tag?.list,
})

export default connect(mapStateToProps, { manageVideos })(FileUploader);