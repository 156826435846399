import React from 'react';
import closeIcon from "../../assets/images/quix-close.png";
import upgradeBanner from "../../assets/images/upgrade-banner.png";
import footerLogoImg from "../../assets/images/quixy-logo.png";
import upgradeIcon from "../../assets/images/quix-upgrade.png";

const UpgradeModal = ({ showModal, closeModal }) => {
    return (
        <>
            <div
                id="quix-dashboard-overlay"
                style={{
                    display: !showModal ? "none" : "block",
                }}
            ></div>
            <div id="quix-dashboard-popup" className='quix-update-account-wrapper'>
                <div className="quix-dashboard-popup-inner pb-0">
                    <div id="quix-update-account-inner">
                        <div className="quix-signin-row"><img alt="" src={upgradeBanner} /></div>
                        <div className="quix-signin-row">
                            <h3>Feature not available!</h3>
                        </div>
                        <div className="quix-signin-row">
                            <span>This feature is not available for free users.<br /> Please upgrade to enjoy
                                using it.
                            </span>
                        </div>
                        <div className="quix-signin-button-social">
                            <div>
                                <img alt="" src={upgradeIcon} />
                                <div>Upgrade</div>
                            </div>
                        </div>
                        <div className="quix-settings-footer">
                            <div className="quix-settings-footer-inner">
                                {/* <span>powered by</span> */}
                                Powered by  <img className="powered_by_logo" src={footerLogoImg} />
                            </div>
                        </div>
                    </div>
                    <div
                        className="quix-dashboard-close"
                        onClick={() => closeModal(false)}
                    >
                        <img src={closeIcon} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpgradeModal;