import React, { useEffect, useState } from "react";
import logoImg from "../../assets/images/home/ocr-logo.png";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import AdSenseAd from "../../components/Adsense/adSenseAd";

const AboutUs = () => {
  // google-adsense
  const [showAd, setShowAd] = useState(window.innerWidth <= 1220);
  useEffect(() => {
    const handleResize = () => {
      setShowAd(window.innerWidth <= 1220);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let formatOfAds = 'horizontal';
  let display = window.innerWidth <= 480 ? 'none' : 'block';
  let showAds = window.innerWidth <= 480 ? false : true;
  let adsStyle = { display: display, maxWidth: '100%', maxHeight: '120px', height: '120px', width: '100%' };

  return (
    <div className="about-us-page main-wrapper">
      <div className="header_section">
        <TopBar />
        <div className="container top_container">
          <header className="ScreenGenius-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img className="sitetitle" src={logoImg} height="80" alt="CoolBrand" />
                </Link>
              </div>
            </nav>
          </header>
        </div>
      </div>
      <div className="content-section">
        {showAds && (
          <div className="container top_container">
            <AdSenseAd
              client="ca-pub-4424794709901498"
              slot="3435754143"
              format={formatOfAds}
              style={adsStyle}
              responsive={true}
            />
          </div>
        )}
        <div className="container top_container">
          <div className="row">
            <div className="star_emoji mx-auto pt-3">
              <span className="thankEmoji">
                <span className="about-us">
                  <span className="emoji_content"></span>
                  <h5>About Us</h5>
                  <p>
                    <b>VividMinds Technologies Private Limited </b>
                    <is>
                      a Hyderabad based Startup firm founded in the year 2019
                      under Startup India program. Vividminds Technologies
                      Private Limited is into IT product development and{" "}
                    </is>
                    <b>"Capios"</b>
                    <is>
                      the flagship product of our organization. Capios
                      is an ultimate solution for all things related to
                      screenshots and screen recordings. Our innovative tool
                      empowers you to effortlessly capture your screen, edit,
                      annotate, and share with ease. With Capios, you
                      gain access to a user-friendly dashboard tailored to
                      your needs, allowing you to conveniently view, edit, and
                      share your media with the world. Join us and experience
                      the simplicity and efficiency of Capios today!
                    </is>
                  </p>
                </span>
              </span>
            </div>
          </div>
        </div>
        {showAds && (
          <div className="container top_container mb-2">
            <AdSenseAd
              client="ca-pub-4424794709901498"
              slot="3435754143"
              format={formatOfAds}
              style={adsStyle}
              responsive={true}
            />
          </div>
        )}
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AboutUs;
