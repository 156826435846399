import React, { useEffect, useState } from "react";
import logoImg from "../../../assets/images/home/ocr-logo.png";
import loadingIcon from "../../../assets/images/light-loader.gif";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../../redux/actions/authentication";
import { Link, useNavigate } from "react-router-dom";
import { failureMessagePopup } from "../../../helper/helper";
import { forgetSchema } from "./Validation";
import SuccessVerifyModal from "../../../components/Modals/SuccessVerifyModal";
import AdSenseAd from "../../../components/Adsense/adSenseAd";
import AuthChecker from "../../../helper/AuthChecker";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [successVerifyMessage, setSuccessVerifyMessage] = useState(false);
  const [formErrors, setFormErrors] = useState("");

  const handleSubmit = () => {
    if (forgetSchema({ formData, setFormErrors })) {
      setLoading(true);
      dispatch(
        forgetPassword(formData, (type, res) => {
          if (type === "success") {
            setMessage(res?.message);
            setLoading(false);
            setSuccessVerifyMessage(true);
          } else if (type === "failed") {
            console.log("Something went wrong!");
            failureMessagePopup("Error Message", res?.message);
            setLoading(false);
          }
        })
      );
    }
  };

  const handleGoLoginPage = (e) => {
    navigate("/login");
  };

  // google-adsense
  const [showAd, setShowAd] = useState(window.innerWidth <= 1220);
  useEffect(() => {
    const handleResize = () => {
      setShowAd(window.innerWidth <= 1220);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let formatOfAds = !showAd ? 'vertical' : 'horizontal';
  let display = window.innerWidth <= 480 ? 'none' : 'block';
  let showAds = window.innerWidth <= 480 ? false : true;
  let adsStyle = { display: display, maxWidth: !showAd ? '400px' : "100%", maxHeight: !showAd ? '600px' : '120px', height: !showAd ? '600px' : '120px', width: !showAd ? '120px' : '100%' };

  return (
    <div className="login-page">
      <AuthChecker />
      <div id="quix-dashboard-wrapper">
        <div id="quix-dashboard-wrapper">
          <div id="quix-dashboard-inner">
            <div className="login-logo-outer">
              <div className="login-logo-inner">
                <Link to={"/"}>
                  <img src={logoImg} />
                </Link>
              </div>
            </div>
            <div className="login-outer-box">
              <div className="adsense-box">
                {showAds && (
                  <AdSenseAd
                    client="ca-pub-4424794709901498"
                    slot="3435754143"
                    format={formatOfAds}
                    style={adsStyle}
                    responsive={true}
                  />
                )}
              </div>
              {/* form outer */}
              <div className="login-form-outer">
                <div className="login-form-inner">
                  <span className="form-title">Forgot your Password</span>
                  <span className="form-subtitle">
                    To get started, please sign in
                  </span>
                </div>
                <div className="login-with-details">
                  <div className="custom-login-details">
                    <div className="custom-details-form card">
                      <div className="card-body">
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <div className="quix-edit-email">
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder="Enter your email"
                              onChange={(e) =>
                                setFormData({ email: e.target.value })
                              }
                              defaultValue={formData?.email}
                              maxLength={255}
                            />
                          </div>
                          {formErrors?.emailError && (
                            <p className="text-danger account-dec m-0">
                              {formErrors?.emailError}
                            </p>
                          )}
                        </div>
                        <div className="form-btn text-center">
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn custom-details-btn login-btn"
                            onClick={() => handleSubmit()}
                          >
                            Send Email
                          </button>

                          {loading && (
                            <img className="loading-btn-img" src={loadingIcon} />
                          )}
                        </div>
                        <p className="account-dec text-center">
                          If you remember your password login here!{" "}
                          <Link to="/login">Login</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* form outer */}
              <div className="adsense-box">
                {showAds && (
                  <AdSenseAd
                    client="ca-pub-4424794709901498"
                    slot="3435754143"
                    format={formatOfAds}
                    style={adsStyle}
                    responsive={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {successVerifyMessage && (
          <SuccessVerifyModal
            message={
              <>
                We have sent a link to your email, Please use <br /> that link
                to create your new password.
              </>
            }
            btnCallBack={(e) => handleGoLoginPage(e)}
            btnTitle={"OK"}
          />
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
