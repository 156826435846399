import { checkAvailableVideoPath, checkNullValue, checkServerURL } from "../../../helper/helper";
import { API_URL } from "../../../utils/config";
import trashIcon from "../../assets/images/tash-user-icon.png";

export const videoCSV = (videoListData) => {
    const videoDataCSVFormate =
        videoListData &&
        videoListData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                UserName: item?.userDetails?.name
                    ? checkNullValue(item?.userDetails?.name)
                    : "N/A",
                Email: item?.userDetails?.email
                    ? checkNullValue(item?.userDetails?.email)
                    : "N/A",
                Picture: item?.userDetails?.picture
                    ? checkNullValue(checkServerURL(API_URL, item?.userDetails?.picture))
                    : "N/A",
                Name: item?.name ? item?.name : "N/A",
                Url: item?.path || item?.path_mp4 || item?.modified_path ? checkNullValue(checkServerURL(API_URL, checkAvailableVideoPath(item?.path, item?.path_mp4, item?.modified_path))) : "N/A",
                Title: item?.title ? checkNullValue(item?.title) : "N/A",
                Summary: item?.summary ? checkNullValue(item?.summary) : "N/A",
                Thumbnail: item?.thumbnail_mod
                    ? checkNullValue(checkServerURL(API_URL, item?.thumbnail_mod))
                    : "N/A",
                FileDuration: item?.file_duration_mod ? checkNullValue(item?.file_duration_mod) : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });
    let videoDataCSV = {
        filename: "videos-list",
        data: videoDataCSVFormate,
    };
    return videoDataCSV;
}

export const csvVideoColumns = [
    { id: "ID", title: "ID" },
    { id: "UserName", title: "UserName" },
    { id: "Email", title: "Email" },
    { id: "Picture", title: "Picture" },
    { id: "Name", title: "Name" },
    { id: "Url", title: "Url" },
    { id: "Title", title: "Title" },
    { id: "Summary", title: "Summary" },
    { id: "Thumbnail", title: "Thumbnail" },
    { id: "FileDuration", title: "FileDuration" },
    { id: "Created_at", title: "Created At" },
    { id: "Updated_at", title: "Updated At" },
]

export const screenshotCSV = (screenshotListData) => {
    const screenshotDataCSVFormate =
        screenshotListData &&
        screenshotListData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                UserName: item?.userDetails?.name ? checkNullValue(item?.userDetails?.name) : "N/A",
                Email: item?.userDetails?.email ? checkNullValue(item?.userDetails?.email) : "N/A",
                Picture: item?.userDetails?.picture
                    ? checkNullValue(checkServerURL(API_URL, item?.userDetails?.picture))
                    : "N/A",
                Name: item?.name ? checkNullValue(item?.name) : "N/A",
                Url: item?.path ? checkNullValue(checkServerURL(API_URL, item?.path)) : "N/A", 
                Title: item?.title ? checkNullValue(item?.title) : "N/A",
                Summary: item?.summary ? checkNullValue(item?.summary) : "N/A",
                Thumbnail: item?.thumbnail
                    ? checkNullValue(checkServerURL(API_URL, item?.thumbnail))
                    : "N/A",
                FileSize: item?.file_size ? checkNullValue(Number(item?.file_size)) : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });
    let screenshotDataCSV = {
        filename: "screenshots-list",
        data: screenshotDataCSVFormate,
    };
    return screenshotDataCSV;
}

export const csvScreenshotColumns = [
    { id: "ID", title: "ID" },
    { id: "UserName", title: "UserName" },
    { id: "Email", title: "Email" },
    { id: "Picture", title: "Picture" },
    { id: "Name", title: "Name" },
    { id: "Url", title: "Url" },
    { id: "Title", title: "Title" },
    { id: "Summary", title: "Summary" },
    { id: "Thumbnail", title: "Thumbnail" },
    { id: "FileSize", title: "FileSize" },
    { id: "Created_at", title: "Created At" },
    { id: "Updated_at", title: "Updated At" },
]

export const confirmationModalMessage = ({ action, type }) => {
    switch (action) {
        case "SCREENSHOT_DELETE":
            return {
                title: <>Would you like to permanently <br />delete this screenshot?</>,
                message: <>Once deleted, this screenshot will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "MULTI_SCREENSHOT_DELETE":
            return {
                title: <>Would you like to permanently <br />delete this screenshot?</>,
                message: <>Once deleted, these screenshot will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "VIDEO_DELETE":
            return {
                title: <>Would you like to permanently <br />delete this video?</>,
                message: <>Once deleted, this video will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        case "MULTI_VIDEO_DELETE":
            return {
                title: <>Would you like to permanently <br />delete selected video?</>,
                message: <>Once deleted, these video will<br />no longer be accessible.</>,
                type: action,
                icon: trashIcon,
                tab: type
            };
        default:
            return null;
    }
}