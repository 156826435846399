import React, { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { localStoragePrefix } from "../utils/config";
import LoadingPage from "../views/loading-page/LoadingPage";
import { useDispatch } from "react-redux";
import { getUserDetailsByID } from "../redux/actions/authentication";

const AuthenticatedRoutes = ({ getUserRole }) => {
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const userRole = 0;
    const storageToken = localStorage.getItem(
        localStoragePrefix + "screenGeniusAuthToken"
    );
    const isRedirected = localStorage.getItem(
        localStoragePrefix + "isRedirected"
    );
    const checkAuth = async () => {
        if((!isRedirected || isRedirected === "false") && !storageToken){
            localStorage.setItem(localStoragePrefix + "isRedirected", true);
            window.location.reload();
            return;
        }
        if (!storageToken) {
            setLoading(false);
            setIsAuth(false);
            navigate("/login");
            return;
        }
        try {
            await dispatch(getUserDetailsByID(storageToken, (result, type) => {
                if (result?.data?.status) {
                    setIsAuth(true);
                    let role = result?.data?.role ?? 0;
                    getUserRole(Number(role));
                } else {
                    if(!isRedirected || isRedirected === "false"){
                        localStorage.setItem(localStoragePrefix + "isRedirected", true);
                        window.location.reload();
                        return;
                    }
                    localStorage.removeItem(localStoragePrefix + "screenGeniusAuthToken");
                    localStorage.removeItem(localStoragePrefix + "screenGeniusUserID");
                    localStorage.removeItem(localStoragePrefix + "screenGeniusUserRole");
                    // localStorage.clear()
                    setIsAuth(false);
                    navigate("/login");
                }
            }));
        } catch (error) {
            if(!isRedirected || isRedirected === "false"){
                localStorage.setItem(localStoragePrefix + "isRedirected", true);
                alert("relloadsss")
                window.location.reload();
                return;
            }
            localStorage.removeItem(localStoragePrefix + "screenGeniusAuthToken");
            localStorage.removeItem(localStoragePrefix + "screenGeniusUserID");
            localStorage.removeItem(localStoragePrefix + "screenGeniusUserRole");
            // localStorage.clear()
            setIsAuth(false);
            navigate("/login");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkAuth();
    }, [location]);

    if (loading) {
        return <LoadingPage opacity={'0.9'} />;
    }

    return isAuth ? <Outlet context={{ userRole }} /> : <Navigate to="/login" replace state={{ redirectTo: location }} />;
};

export default AuthenticatedRoutes;