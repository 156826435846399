import React, { useEffect, useState } from "react";
import AdminLayout from "../../layout/adminLayout";
import { Link, useLocation, useParams } from "react-router-dom";
import leftArrowIcon from "../../assets/images/arrow-left.png";
import userDummyIcon from "../../assets/images/dumy-user.jpg";
import smsIcon from "../../assets/images/sms-icon.png";
import videoIcon from "../../assets/images/video-icon.png";
import screenshotIcon from "../../assets/images/gallery-icon.png";
import trashIcon from "../../assets/images/trash-icon.png";
import trashUserIcon from "../../assets/images/tash-user-icon.png";
import actionIcon from "../../assets/images/action-icon.png";
import editVideoIcon from "../../assets/images/video-icon.png";
import editImageIcon from "../../assets/images/image-icon.png";
import MediaContentCard from "../../components/Cards/MediaContentCard";
import Table from "../../components/Table/Table";
import Filters from "../../components/Filters/Filters";
import FilterContent from "../../components/Filters/FilterContent";
import { connect, useDispatch } from "react-redux";
import {
    manageScreenShots,
    manageSearchScreenShots,
} from "../../../redux/actions/manageScreenshots";
import { checkServerURL, sg_format_bytes } from "../../../helper/helper";
import dateFormat from "dateformat";
import {
    manageSearchVideos,
    manageVideos,
} from "../../../redux/actions/manageVideos";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import EditActionModal from "../../components/Modal/EditActionModal";
import VideoViewCard from "../../components/Cards/VideoViewCard";
import ScreenshotViewCard from "../../components/Cards/ScreenshotViewCard";
import EditUserProfile from "./components/EditUserProfile";
import { getUserByIdAdmin } from "../../../redux/actions/admin/users";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { deleteVideoAdmin } from "../../../redux/actions/admin/videos";
import { successMessageModal } from "../../components/Modal/MessagesModal";
import { deleteScreenshotAdmin } from "../../../redux/actions/admin/screenshots";
import dummyUserIcon from "../../assets/images/dumy-user.jpg";
import {
    confirmationModalMessage,
    handleChangeSelectedItems,
    handleMultiSelectItems,
    userScreenshotCSV,
    userVideoCSV,
    csvVideoColumns,
    csvScreenshotColumns
} from "./helper";

const ManageUserProfile = ({
    userDetails,
    screenshotList,
    screenshotLoading,
    screenshotNotFound,
    videoList,
    videoLoading,
    videoNotFound,
}) => {
    const param = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showScreenshotModal, setShowScreenshotModal] = useState(false);
    const [videoCardData, setVideCardData] = useState(null);
    const [screenshotCardData, setScreenshotCardData] = useState(null);
    const [loading, setLoading] = useState(false);
    const adminID = localStorage.getItem(
        localStoragePrefix + "screenGeniusUserID"
    );
    const userData = userDetails?.user_details ? userDetails?.user_details : [];
    const userLocationData = location?.state?.data;
    const userID =
        userLocationData?.id !== "undefined" ? userLocationData?.id : param?.id;
    const userToken =
        userLocationData?.access_token !== "undefined"
            ? userLocationData?.access_token
            : userData?.access_token;
    const screenshotData = screenshotList?.data ? screenshotList?.data : [];
    const videoData = videoList?.data ? videoList?.data : [];
    const userPayload = {
        adminID: adminID,
        id: userID,
    };
    useEffect(() => {
        dispatch(getUserByIdAdmin(userPayload, (type, res) => { }));
    }, [userID]);
    const userProfile =
        !userData?.picture ||
            userData?.picture === "null" ||
            userData?.picture === null
            ? dummyUserIcon
            : checkServerURL(API_URL, userData?.picture);
    const userName = userData?.name && userData?.name;
    const userEmail = userData?.email && userData?.email;
    const activePlan = userData?.pricing_plan && userData?.pricing_plan;

    useEffect(() => {
        if (userID) {
            // get all users videos
            dispatch(manageVideos(Number(userID), userToken));
            // get all users screenshots
            dispatch(manageScreenShots(Number(userID), userToken));
        }
    }, [userID]);

    const userVideoDataCSV = userVideoCSV(videoData);
    const userScreenshotDataCSV = userScreenshotCSV(screenshotData);
    // handle search screenshot
    const handleSearchScreenshot = (text) => {
        const payload = {
            user_id: Number(userID),
            access_token: userToken,
            search_text: text,
        };
        dispatch(manageSearchScreenShots(payload));
    };

    // handle search video
    const handleSearchVideo = (text) => {
        const payload = {
            user_id: Number(userID),
            access_token: userToken,
            search_text: text,
        };
        dispatch(manageSearchVideos(payload));
    };

    // reset searched video and screenshot
    const resetScreenshotAndVideo = () => {
        dispatch(manageScreenShots(Number(userID), userToken));
        dispatch(manageVideos(Number(userID), userToken));
    };

    const [showTableAction, setShowTableAction] = useState(false);
    const [showScreenshotUserData, setShowScreenshotUserData] = useState([]);
    const visibleScreenshots = showScreenshotUserData && showScreenshotUserData;
    const [isSlctScreenshot, setIsSlctScreenshot] = useState(false);
    const [slctScreenshotIds, setSlctScreenshotIds] = useState([]);

    useEffect(() => {
        setIsSlctScreenshot(
            visibleScreenshots.length > 0 &&
            visibleScreenshots.every((item) => slctScreenshotIds.includes(item.id))
        );
        if (slctScreenshotIds?.length > 0) {
            setShowTableAction(true);
        } else {
            setShowTableAction(false);
        }
    }, [slctScreenshotIds, visibleScreenshots]);

    // screen table column
    const screenshotsColumn = [
        {
            Header: (
                <input
                    className="form-check-input check-items"
                    type="checkbox"
                    onChange={(event) =>
                        handleMultiSelectItems({
                            event,
                            selectedItemsIds: setSlctScreenshotIds,
                            data: visibleScreenshots,
                        })
                    }
                    checked={isSlctScreenshot ? true : false}
                />
            ),
            accessor: "check",
            disableSortBy: true,
            Cell: (row) => (
                <>
                    <input
                        className="form-check-input check-items"
                        type="checkbox"
                        checked={
                            slctScreenshotIds?.includes(row?.row?.original?.id) ? true : false
                        }
                        onChange={(event) =>
                            handleChangeSelectedItems({
                                event,
                                selectedItemsIds: setSlctScreenshotIds,
                                id: row?.row?.original?.id,
                            })
                        }
                    />
                </>
            ),
        },
        { Header: "#", accessor: (row, i) => i + 1 },
        {
            Header: "Content",
            accessor: "screenshot",
            Cell: (row) => (
                <>
                    <MediaContentCard
                        src={
                            !row?.row?.original?.thumbnail ||
                                row?.row?.original?.thumbnail === "null" ||
                                row?.row?.original?.thumbnail === null
                                ? ""
                                : checkServerURL(API_URL, `${row?.row?.original?.thumbnail}`)
                        }
                        title={row?.row?.original?.title}
                        duration={`${sg_format_bytes(
                            row?.row?.original?.file_size
                        )} - Screenshot`}
                    ></MediaContentCard>
                </>
            ),
        },
        {
            Header: "Date & time",
            Cell: (row) => (
                <>{dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")}</>
            ),
        },
        {
            Header: "Action",
            Cell: (row) => (
                <div className="action-cell">
                    <div className="edit-action-cell">
                        <img className="action-img" src={actionIcon} />
                        <EditActionModal>
                            <div
                                className="column-action gap-2"
                                onClick={() => {
                                    setShowScreenshotModal(!showScreenshotModal);
                                    setScreenshotCardData(row?.row?.original);
                                }}
                            >
                                <img src={editImageIcon} />
                                <span>Screenshot details</span>
                            </div>
                            <div
                                className="column-action delete-action gap-2"
                                disabled={loading}
                                onClick={() => {
                                    setScreenshotCardData(row?.row?.original);
                                    modalAction("SCREENSHOT_DELETE", "screenshot");
                                }}
                            >
                                <img src={trashIcon} />
                                <span>Delete content</span>
                            </div>
                        </EditActionModal>
                    </div>
                </div>
            ),
        },
    ];

    // video select
    const [showVideoTableAction, setShowVideoTableAction] = useState(false);
    const [showVideoUserData, setShowVideoUserData] = useState([]);
    const visibleVideos = showVideoUserData && showVideoUserData;
    const [isSlctVideo, setIsSlctVideo] = useState(false);
    const [slctVideoIds, setSlctVideoIds] = useState([]);

    useEffect(() => {
        setIsSlctVideo(
            visibleVideos.length > 0 &&
            visibleVideos.every((item) => slctVideoIds.includes(item.id))
        );
        if (slctVideoIds?.length > 0) {
            setShowVideoTableAction(true);
        } else {
            setShowVideoTableAction(false);
        }
    }, [slctVideoIds, visibleVideos]);

    // video table columns
    const videosColumn = [
        {
            Header: (
                <input
                    className="form-check-input check-items"
                    type="checkbox"
                    onChange={(event) =>
                        handleMultiSelectItems({
                            event,
                            selectedItemsIds: setSlctVideoIds,
                            data: visibleVideos,
                        })
                    }
                    checked={isSlctVideo ? true : false}
                />
            ),
            accessor: "check",
            disableSortBy: true,
            Cell: (row) => (
                <>
                    <input
                        className="form-check-input check-items"
                        type="checkbox"
                        checked={
                            slctVideoIds?.includes(row?.row?.original?.id) ? true : false
                        }
                        onChange={(event) =>
                            handleChangeSelectedItems({
                                event,
                                selectedItemsIds: setSlctVideoIds,
                                id: row?.row?.original?.id,
                            })
                        }
                    />
                </>
            ),
        },
        { Header: "#", accessor: (row, i) => i + 1 },
        {
            Header: "Content",
            accessor: "video",
            Cell: (row) => (
                <>
                    <MediaContentCard
                        src={
                            !row?.row?.original?.thumbnail_mod ||
                                row?.row?.original?.thumbnail_mod === "null" ||
                                row?.row?.original?.thumbnail_mod === null
                                ? ""
                                : checkServerURL(API_URL, `${row?.row?.original?.thumbnail_mod}`)
                        }
                        title={row?.row?.original?.title}
                        duration={`${row?.row?.original?.file_duration_mod} min`}
                    ></MediaContentCard>
                </>
            ),
        },
        {
            Header: "Date & time",
            Cell: (row) => (
                <>{dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")}</>
            ),
        },
        {
            Header: "Action",
            Cell: (row) => (
                <div className="action-cell">
                    <div className="edit-action-cell">
                        <img className="action-img" src={actionIcon} />
                        <EditActionModal>
                            <div
                                className="column-action gap-2"
                                onClick={() => {
                                    setShowVideoModal(!showVideoModal);
                                    setVideCardData(row?.row?.original);
                                }}
                            >
                                <img src={editVideoIcon} />
                                <span>Video details</span>
                            </div>
                            <div
                                className="column-action delete-action gap-2"
                                disabled={loading}
                                onClick={() => {
                                    setVideCardData(row?.row?.original);
                                    modalAction("VIDEO_DELETE", "video");
                                }}
                            >
                                <img src={trashIcon} />
                                <span>Delete content</span>
                            </div>
                        </EditActionModal>
                    </div>
                </div>
            ),
        },
    ];

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        action: "",
        title: "",
        message: "",
        type: "",
        icon: null,
    });
    const modalAction = (action, type) => {
        const modalContent = confirmationModalMessage({
            action: action,
            type: type,
        });
        if (modalContent) {
            setShowConfirmModal(true);
            setModalContent(modalContent);
        }
    };
    const handleScreenshotDeleteAction = (res) => {
        setLoading(true);
        setSlctScreenshotIds([]);
        setShowConfirmModal(false);
        if (res) {
            const payload = {
                admin_id: Number(adminID),
                id: res?.id,
            };
            dispatch(
                deleteScreenshotAdmin(payload, (type, res) => {
                    if (type === "success") {
                        successMessageModal(
                            "Successful Deleted screenshot.",
                            "Screenshot will permanently delete successfully."
                        );
                        setLoading(false);
                        dispatch(getUserByIdAdmin(userPayload, (type, res) => { }));
                        dispatch(manageScreenShots(Number(userID), userToken));
                    } else if (type === "fail") {
                        setLoading(false);
                    }
                })
            );
        }
    };
    const handleVideoDeleteAction = (res) => {
        setLoading(true);
        setSlctVideoIds([]);
        setShowConfirmModal(false);
        if (res) {
            const payload = {
                admin_id: Number(adminID),
                id: res?.id,
            };
            dispatch(
                deleteVideoAdmin(payload, (type, res) => {
                    if (type === "success") {
                        successMessageModal(
                            "Successful Deleted video.",
                            "video will permanently delete successfully."
                        );
                        setLoading(false);
                        dispatch(getUserByIdAdmin(userPayload, (type, res) => { }));
                        dispatch(manageVideos(Number(userID), userToken));
                    } else if (type === "fail") {
                        setLoading(false);
                    }
                })
            );
        }
    };

    const callBackAction = (res, type, tab) => {
        if (res) {
            switch (type) {
                case "SCREENSHOT_DELETE":
                    return handleScreenshotDeleteAction({ id: [screenshotCardData?.id] });
                case "MULTI_SCREENSHOT_DELETE":
                    return handleScreenshotDeleteAction({ id: slctScreenshotIds });
                case "VIDEO_DELETE":
                    return handleVideoDeleteAction({ id: [videoCardData?.id] });
                case "MULTI_VIDEO_DELETE":
                    return handleVideoDeleteAction({ id: slctVideoIds });
                default:
                    return null;
            }
        }
    };

    return (
        <div className="manage-user-screenshot-and-video">
            <AdminLayout>
                <div className="manage-user-screenshot-and-video-content">
                    <div className="user-management-section">
                        <div className="user-management-header">
                            <Link
                                to={"/admin/manage-users"}
                                className="user-manage-bk-btn theme-btn-outline btn"
                            >
                                <img src={leftArrowIcon} />
                                Back to User management
                            </Link>
                        </div>
                        <div className="user-profile-details-section d-flex justify-content-between">
                            <div className="user-profile-details d-flex gap-3">
                                <div className="card profile-card">
                                    <img src={userProfile} alt="profile" />
                                </div>
                                <div className="profile-detail">
                                    <div className="user-name-and-status d-flex gap-3 mt-2">
                                        <h4 className="text-clip" title={userName ? userName : ""}>
                                            {userName ? userName : ""}
                                        </h4>
                                        {activePlan?.price_string ? (<span>{activePlan?.price_string}</span>) : ''}
                                        {/* <span>Active pro plan</span> */}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img src={smsIcon} />
                                        <a href={`mailto:${userEmail}`}>{userEmail}</a>
                                    </div>
                                    <div className="statistics-content mt-4 mb-0">
                                        <div className="statistics-card d-flex align-items-center">
                                            <h6 className="d-flex align-items-center">
                                                <img src={videoIcon} />
                                                {userDetails?.total_video !== "undefined"
                                                    ? userDetails?.total_video
                                                    : 0}
                                            </h6>
                                            <span>Video Recording</span>
                                        </div>
                                        <div className="statistics-card d-flex align-items-center">
                                            <h6 className="d-flex align-items-center">
                                                <img src={screenshotIcon} />
                                                {userDetails?.total_screenshots !== "undefined"
                                                    ? userDetails?.total_screenshots
                                                    : 0}
                                            </h6>
                                            <span>Screenshots & Images</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="user-details-action  d-flex gap-2">
                                <button
                                    className="btn theme-blue-btn edit-profile-btn"
                                    onClick={() => setShowEditUserModal(!showEditUserModal)}
                                >
                                    Edit Profile
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="data-content-section">
                    <Filters
                        filterList={[
                            {
                                id: "video",
                                className: "video active",
                                title: "Video",
                            },
                            {
                                id: "screenshot",
                                className: "screenshot",
                                title: "Screenshot",
                            },
                        ]}
                    />
                    <FilterContent
                        filterContent={[
                            {
                                id: "video",
                                className: "show active video-tab",
                                childrenData: (
                                    <Table
                                        csvColumns={csvVideoColumns}
                                        tableData={videoData}
                                        tableColumns={videosColumn}
                                        csvData={userVideoDataCSV}
                                        isLoading={videoLoading}
                                        notFound={videoNotFound}
                                        searchCallBack={(e) => handleSearchVideo(e)}
                                        resetCallBack={() => resetScreenshotAndVideo()}
                                        searchBar={true}
                                        tableType={"user_video"}
                                        setShowItems={setShowVideoUserData}
                                        showAction={showVideoTableAction}
                                        actionBtn={
                                            <>
                                                <button
                                                    className="btn delete-btn"
                                                    onClick={() => {
                                                        modalAction("MULTI_VIDEO_DELETE", "video");
                                                    }}
                                                >
                                                    <img src={trashIcon} />
                                                    Delete
                                                </button>
                                            </>
                                        }
                                    />
                                ),
                            },
                            {
                                id: "screenshot",
                                className: "screenshot-tab",
                                childrenData: (
                                    <Table
                                        csvColumns={csvScreenshotColumns}
                                        tableData={screenshotData}
                                        tableColumns={screenshotsColumn}
                                        csvData={userScreenshotDataCSV}
                                        isLoading={screenshotLoading}
                                        notFound={screenshotNotFound}
                                        setShowItems={setShowScreenshotUserData}
                                        searchCallBack={(e) => handleSearchScreenshot(e)}
                                        resetCallBack={() => resetScreenshotAndVideo()}
                                        searchBar={true}
                                        tableType={"user_screenshot"}
                                        showAction={showTableAction}
                                        actionBtn={
                                            <>
                                                <button
                                                    className="btn delete-btn"
                                                    onClick={() => {
                                                        modalAction(
                                                            "MULTI_SCREENSHOT_DELETE",
                                                            "screenshot"
                                                        );
                                                    }}
                                                >
                                                    <img src={trashIcon} />
                                                    Delete
                                                </button>
                                            </>
                                        }
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
                {showEditUserModal && (
                    <EditUserProfile
                        userData={userData}
                        isShowModal={showEditUserModal}
                        closeModal={setShowEditUserModal}
                    />
                )}
                {showVideoModal && (
                    <VideoViewCard
                        closeModal={setShowVideoModal}
                        cardData={videoCardData}
                    />
                )}
                {showScreenshotModal && (
                    <ScreenshotViewCard
                        closeModal={setShowScreenshotModal}
                        cardData={screenshotCardData}
                    />
                )}
                {showConfirmModal && (
                    <ConfirmationModal
                        isShowModal={showConfirmModal}
                        closeModal={setShowConfirmModal}
                        callBack={(res) =>
                            callBackAction(res, modalContent?.type, modalContent?.tab)
                        }
                        isLoading={false}
                        isDisabled={false}
                        icon={modalContent?.icon}
                        btnText={"Continue"}
                        messageTitle={modalContent?.title}
                        description={modalContent?.message}
                    />
                )}
            </AdminLayout>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state?.users?.user_details,
    screenshotList: state?.screen_shorts?.list,
    screenshotLoading: state?.screen_shorts?.is_loading,
    screenshotNotFound: state?.screen_shorts?.not_found,
    videoList: state?.videos?.list,
    videoLoading: state?.videos?.is_loading,
    videoNotFound: state?.videos?.not_found,
});

export default connect(mapStateToProps, {
    getUserByIdAdmin,
    manageScreenShots,
    manageVideos,
})(ManageUserProfile);
