import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingPage from '../views/loading-page/LoadingPage';
import { getUserDetailsByID } from '../redux/actions/authentication';
import { localStoragePrefix } from '../utils/config';

const AuthChecker = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const storageToken = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");

    const checkAuth = async () => {
        try {
            await dispatch(getUserDetailsByID(storageToken, (result, type) => {
                if (result?.data?.status) {
                    navigate('/dashboard');
                }
            }));
        } catch (error) {
            console.log(error, '---error--');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkAuth();
    }, [location]);

    if (loading) {
        return <LoadingPage opacity={'0.9'} />;
    }

    return null;
};

export default AuthChecker;