import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    editScreenshotTitleAndSummary,
    manageScreenShots,
} from "../../redux/actions/manageScreenshots";
import {
    checkEmpty,
    failureMessagePopup,
    successMessagePopup,
} from "../../helper/helper";
import closeIcon from "../../assets/images/quix-close.png";
import {
    editVideoTitleAndSummary,
    manageVideos,
} from "../../redux/actions/manageVideos";
import { localStoragePrefix } from "../../utils/config";
import LoadingPage from "../../views/loading-page/LoadingPage";

const EditTitleSummaryCard = ({
    showModal,
    closeModal,
    itemType,
    itemID,
    userID,
    cardData,
}) => {
    const accessToken = localStorage.getItem(
        localStoragePrefix + "screenGeniusAuthToken"
    );
    const dispatch = useDispatch();
    const [formDetails, setFormDetails] = useState({
        title: "",
        summary: "",
    });

    const itemTitle = cardData?.title && cardData?.title;
    const itemSummary = cardData?.summary && cardData?.summary;
    useEffect(() => {
        setFormDetails({
            title: itemTitle,
            summary: itemSummary,
        });
    }, [itemTitle, itemSummary]);

    const [laoding, setLoading] = useState(false);
    const handleSubmit = () => {
        const payload = {
            title: formDetails?.title,
            summary: formDetails?.summary,
            user_id: userID,
            id: itemID,
            access_token: accessToken,
        };
        setLoading(true);
        if (itemType === "image") {
            // Dispatch action to edit screenshot title and summary
            dispatch(
                editScreenshotTitleAndSummary(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup("Success Message", "Edit successfully!");
                        dispatch(manageScreenShots(userID, accessToken)); // Refresh screenshots list
                        closeModal(false);
                        setLoading(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message", res?.message);
                        closeModal(false);
                        setLoading(false);
                    }
                })
            );
        } else if (itemType === "video") {
            // Dispatch action to edit video title and summary
            dispatch(
                editVideoTitleAndSummary(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup("Success Message", "Edit successfully!");
                        dispatch(manageVideos(userID, accessToken)); // Refresh videos list
                        closeModal(false);
                        setLoading(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message", res?.message);
                        closeModal(false);
                        setLoading(false);
                    }
                })
            );
        }
    };

    return (
        <>
            <div
                id="quix-dashboard-overlay"
                style={{
                    display: !showModal ? "none" : "block",
                }}
            ></div>
            {laoding && <LoadingPage opacity={'0.9'} /> }
            <div id="quix-dashboard-popup">
                <div className="quix-dashboard-popup-inner">
                    <div className="quix-dashboard-popup-title">
                        <div className="quix-dashboard-popup-title-inner">
                            <span>Edit Title/Summary</span>
                        </div>
                    </div>
                    <div className="quix-dashboard-popup-body">
                        <div className="quix-dashboard-popup-body-inner">
                            <div className="quix-upload-outer text-center">
                                <div className="col-md-12 mb-1 text-start">
                                    <label className="col-form-label">Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        className="form-control"
                                        onChange={(e) =>
                                            setFormDetails({ ...formDetails, title: e.target.value })
                                        }
                                        defaultValue={checkEmpty(formDetails?.title)}
                                        maxLength={255}
                                    ></input>
                                </div>
                                <div className="col-md-12 mb-1 text-start">
                                    <label className="col-form-label">Summary</label>
                                    <textarea
                                        type="text"
                                        name="summary"
                                        className="form-control"
                                        onChange={(e) =>
                                            setFormDetails({
                                                ...formDetails,
                                                summary: e.target.value,
                                            })
                                        }
                                        defaultValue={checkEmpty(formDetails?.summary)}
                                    ></textarea>
                                </div>
                                <div className="col-md-12 mt-4">
                                    <button
                                        onClick={() => closeModal(false)}
                                        className="btn form-action-button form-cancel-button"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => handleSubmit()}
                                        className="btn form-action-button form-save-button"
                                    >
                                        Save
                                    </button>
                                </div>
                                <div
                                    className="quix-dashboard-close"
                                    onClick={() => closeModal(false)}
                                >
                                    <img src={closeIcon} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditTitleSummaryCard;
