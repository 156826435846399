import React from "react";
import uninsatllImg from "../../assets/images/home/ocr-uninstall-image.png";
import logoImg from "../../assets/images/home/ocr-logo.png";
import { Link } from "react-router-dom";
import AdSenseAd from "../../components/Adsense/adSenseAd";
import FeedBackForm from "./components/FeedBackForm";

const Unintall = () => {
  // google-adsense
  let formatOfAds = 'horizontal';
  let display = window.innerWidth <= 480 ? 'none' : 'block';
  let showAds = window.innerWidth <= 480 ? false : true;
  let adsStyle = { display: display, maxWidth: '100%', maxHeight: '120px', height: '120px', width: '100%' };

  return (
    <div className="uninstall-page" id="root">
      <div className="header_section">
        <div className="container top_container">
          <header className="ScreenGenius-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid justify-content-center">
                <Link className="navbar-brand" to="/">
                  <img src={logoImg} height="80" alt="CoolBrand" />
                </Link>
              </div>
            </nav>
          </header>
          <div className="container-fluid">
            <div className="row">
              <div className="star_emoji col-sm-7 mx-auto pt-3">
                <span className="thankEmoji">
                  <span className="emoji">
                    <img src={uninsatllImg} />
                    <span className="emoji_content">
                      <div className="section_title">
                        <h2>
                          Capios{" "}
                          <span className="heading-badge-text">
                            successfully uninstalled
                          </span>{" "}
                          from your device now!
                        </h2>
                        <p>
                          Your experience matters to us. Would you mind taking
                          few minutes to share your feedback on our product?
                        </p>
                      </div>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feedback-section screen_recording_game">
        <div className="row m-0">
          <FeedBackForm />
          {showAds && (
            <div className="extension_screen ads-sense mb-2 mt-0">
              <AdSenseAd
                client="ca-pub-4424794709901498"
                slot="3435754143"
                format={formatOfAds}
                style={adsStyle}
                responsive={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Unintall;
