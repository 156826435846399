import React from "react";
import {
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const Stripe = ({ children, stripeConfig }) => {
  let stripeKay = stripeConfig?.stripe_api_key && stripeConfig?.stripe_api_key;
  const stripePromise = loadStripe(`${stripeKay}`);
  const options = {
    mode: 'subscription',
    amount: 1099,
    currency: 'usd',
    appearance: {
      /*...*/
    },
  };
  return <>
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  </>;
};

export default Stripe;
