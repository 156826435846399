import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import ImageCard from "../../components/Cards/ImageCard";
import VideoCard from "../../components/Cards/VideoCard";
import TabsLayout from "../../components/TabsLayout/TabsLayout";
import { connect, useDispatch } from "react-redux";
import { manageStarredScreenShorts } from "../../redux/actions/manageScreenshots";
import { manageStarredVideos } from "../../redux/actions/manageVideos";
import NotFound from "../../components/NotFound/NotFound";
import { sg_handle_multiselect } from "../../helper/helper";
import Loader from "../../components/Loader/Loader";
import { localStoragePrefix } from "../../utils/config";
import LoadingPage from "../loading-page/LoadingPage";

const Starred = ({
  starredScreenShorts,
  starredVideos,
  notFoundScreenShorts,
  notFoundVideos,
  loadingScreenshorts,
  loadingVideos,
}) => {
  const dispatch = useDispatch();
  const userID = localStorage.getItem(localStoragePrefix + "screenGeniusUserID");
  const accessToken = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");

  useEffect(() => {
    if (userID && accessToken) {
      dispatch(manageStarredScreenShorts(Number(userID), accessToken));
      dispatch(manageStarredVideos(Number(userID), accessToken));
    }
  }, [dispatch, userID]);
  // get sharred screenshort
  const starredScreenShortsData =
    starredScreenShorts?.data && starredScreenShorts?.data;
  // get sharred videos
  const starredVideo = starredVideos?.data && starredVideos?.data;
  // multiselect screenshot
  const [selectedScreenshotIDs, setSelectedScreenshotIDs] = useState([]);
  const [selectedVideoIDs, setSelectedVideoIDs] = useState([]);
  const [isActiveTab, setActiveTab] = useState("quix-tab-images");
  // multiple select screenshots & videos
  const handleMultipleSelect = (e, type) => {
    const checked = e.target.checked;
    if (type === 'screenshot') {
      if (checked) {
        let screenshotIDs = starredScreenShortsData?.filter(items => items?.id)?.map(el => el.id) || [];
        setSelectedScreenshotIDs(screenshotIDs);
      } else {
        setSelectedScreenshotIDs([]);
      }
    } else if (type === 'video') {
      if (checked) {
        let VideoIDs = starredVideo?.filter(items => items?.id)?.map(el => el.id) || [];
        setSelectedVideoIDs(VideoIDs);
      } else {
        setSelectedVideoIDs([]);
      }
    }
  }
  const [isLoading,setIsLoading] = useState(false);
  {isLoading && (<LoadingPage opacity={'0.9'}/>)}

  return (
    <Layout page={"starred"}>
      <div id="quix-dashboard-content">
        <TabsLayout
          selectedScreenshotIDs={selectedScreenshotIDs}
          selectedVideoIDs={selectedVideoIDs}
          isActivePage={"starred"}
          isActiveTab={setActiveTab}
        >
          {/*Starred Images Content */}
          <div
            className={`tab-pane fade ${isActiveTab === "quix-tab-images" ? "show active" : ""
              }`}
            id="screen-genius-images"
            role="tabpanel"
            aria-labelledby="quix-tab-images"
          >
            <div className="quix-dashboard-content-images">
              {!notFoundScreenShorts && starredScreenShortsData && (
                <div className="quix-multi-select form-check mx-4 mb-3">
                  <input onChange={(e) => handleMultipleSelect(e, 'screenshot')} className="form-check-input" name="multi-screenshot-select" type="checkbox" value="" id="multi-select-screenshot" />
                  <label className="form-check-label" htmlFor="multi-select-screenshot">
                    Select all Screenshots.
                  </label>
                </div>
              )}
              <div className={loadingScreenshorts ? "quix-data-loading" : ""}>
                {loadingScreenshorts ? (
                  <Loader />
                ) : !notFoundScreenShorts && starredScreenShortsData ? (
                  <div className="quix-dashboard-content-innr">
                    {starredScreenShortsData.map((items, ind) => (
                      <ImageCard
                        userDetails={null}
                        key={ind}
                        cardData={items}
                        isCheckedCard={selectedScreenshotIDs?.includes(
                          items?.id
                        )}
                        handleChangeChecked={(id) =>
                          sg_handle_multiselect(id, setSelectedScreenshotIDs)
                        }
                        setIsLoading={setIsLoading}
                      />
                    ))}
                  </div>
                ) : (
                  <NotFound />
                )}
              </div>
            </div>
          </div>
          {/*Starred Videos Content */}
          <div
            className={`tab-pane fade ${isActiveTab === "quix-tab-videos" ? "show active" : ""
              }`}
            id="screen-genius-videos"
            role="tabpanel"
            aria-labelledby="quix-tab-videos"
          >
            <div className="quix-dashboard-content-videos">
              {!notFoundVideos && starredVideo && (
                <div className="quix-multi-select form-check mx-4 mb-3">
                  <input onChange={(e) => handleMultipleSelect(e, 'video')} className="form-check-input" name="multi-video-select" type="checkbox" value="" id="multi-select-video" />
                  <label className="form-check-label" htmlFor="multi-select-video">
                    Select all Screen Recordings.
                  </label>
                </div>
              )}
              <div className={`${loadingVideos ? "quix-data-loading" : ""}`}>
                {loadingVideos ? (
                  <Loader />
                ) : !notFoundVideos && starredVideo ? (
                  <div className="quix-dashboard-content-innr ">
                    {starredVideo &&
                      starredVideo?.map((items, ind) => {
                        return (
                          <VideoCard
                            userDetails={null}
                            key={ind}
                            cardData={items}
                            isCheckedCard={selectedVideoIDs?.includes(
                              items?.id
                            )}
                            handleChangeChecked={(id) =>
                              sg_handle_multiselect(id, setSelectedVideoIDs)
                            }
                            setIsLoading={setIsLoading}
                          />
                        );
                      })}
                  </div>
                ) : (
                  <NotFound />
                )}
              </div>
            </div>
          </div>
        </TabsLayout>
      </div>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    loadingScreenshorts: state?.screen_shorts?.is_loading_started_screenshorts,
    loadingVideos: state?.videos?.is_loading_started_videos,
    starredScreenShorts: state?.screen_shorts?.starred_list,
    notFoundScreenShorts: state?.screen_shorts?.starred_not_found,
    starredVideos: state?.videos?.starred_list,
    notFoundVideos: state?.videos?.starred_not_found,
  };
};

export default connect(mapStateToProps, {
  manageStarredScreenShorts,
  manageStarredVideos,
})(Starred);
