import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import { ToastContainer } from "react-toastify";
import LoadingPage from "../../views/loading-page/LoadingPage";
import ResposiveNavbar from "../components/ResposiveNavbar/ResposiveNavbar";

const AdminLayout = ({ children, pageTitle }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="quix-admin-layout-wrapper admin-fonts">
      <ToastContainer autoClose={20000} />
      <div className="quix-admin-layout">
        <div className="resposive-menu-toggler">
          <ResposiveNavbar showMenu={showMenu} setShowMenu={setShowMenu} />
        </div>
        <div className="quix-admin-layout-inner">
          <div className="quix-admin-sidebar-outer" style={{ display: !showMenu ? 'none' : 'block' }}>
            <Sidebar pageTitle={pageTitle} isLoading={setIsLoading} />
          </div>
          {isLoading && (
            <LoadingPage opacity={'0.9'} />
          )}
          <div className="quix-admin-layout-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
