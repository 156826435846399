import React, { useEffect, useState, useRef } from "react";
import { VideoEditor } from "./editor";
import { json, useNavigate, useParams } from "react-router-dom";
import {
  checkAvailableVideoPath,
  checkServerURL,
  failureMessagePopup,
  successMessagePopup,
} from "../../helper/helper";
import axios from "axios";

const EditVideoModul = () => {
  const API_URL = `http://13.232.41.141:5000`;
  const navigate = useNavigate();
  const manageEditVideoDetails = async (payload, callBack) => {
    try {
      const FormData = require("form-data");
      let data = new FormData();
      data.append("video_id", payload?.id);
      data.append("video_path", payload?.videUrl);
      data.append("user_id", payload?.user_id);
      data.append("access_token", payload?.access_token);
      data.append("text_points", JSON.stringify(payload?.text_points));
      data.append("image_points", JSON.stringify(payload?.image_points));
      data.append("video_points", JSON.stringify(payload?.video_points));
      data.append("blur_points", JSON.stringify(payload?.blur_points));
      data.append("crop_points", JSON.stringify(payload?.crop_points));
      data.append("bgMusic_points", JSON.stringify(payload?.bgMusic_points));
      data.append("video_speed", JSON.stringify(payload?.video_speed));
      data.append("video_dimension", JSON.stringify(payload?.video_dimension));
      data.append("transcript", JSON.stringify(payload?.transcript));
      data.append("video_effects", JSON.stringify(payload?.video_effects));
      data.append("insert_points", JSON.stringify(payload?.insert_points));
      data.append("waterMark", JSON.stringify(payload?.waterMark));
      data.append("gif_points", JSON.stringify(payload?.gif_points));
      data.append("video_tacked", JSON.stringify(payload?.video_tacked));
      data.append(
        "remove_filler_words",
        JSON.stringify(payload?.remove_filler_words)
      );
      data.append("remove_silence", JSON.stringify(payload?.remove_silence));

      payload?.bgMusic &&
        payload?.bgMusic?.map((file) => {
          data.append("bg_files", file);
        });
      payload?.bgImage &&
        payload?.bgImage?.map((file) => {
          data.append("bg_files", file);
        });
      payload?.bgVideo &&
        payload?.bgVideo?.map((file) => {
          data.append("bg_files", file);
        });
      payload?.insertData &&
        payload?.insertData?.map((file) => {
          data.append("bg_files", file);
        });
      if (payload?.watermarkImage) {
        data.append("bg_files", payload?.watermarkImage);
      }

      const res = await axios.post(`${API_URL}/videos/edit`, data);
      if (res) {
        callBack("success", res?.data);
      }
    } catch (error) {
      let message = error?.response?.data;
      if (error?.response) {
        callBack("fail", message);
      } else {
        let message = { message: "Something went wrong!" };
        callBack("fail", message);
      }
    }
  };

  const getEditedVideoDetails = (data, callBack) => {
    const payload = {
      id: null,
      access_token: null,
      user_id: null,
      ...data,
    };
    manageEditVideoDetails(payload, (type, res) => {
      if (type === "success") {
        successMessagePopup("Success!", "Video Edited successfully.");
        if (res.gifPaths && res.gifPaths.length > 0) {
          for (var i = 0; i < res.gifPaths.length; i++) {
            let el = document.createElement("a");
            el.setAttribute("href", checkServerURL(API_URL, res.gifPaths[i]));
            el.setAttribute("download", 'download');
            el.setAttribute("target", '_blank');
            document.body.appendChild(el);
            try {
              el.click();
            }
            catch (err) {
              alert(err + "Please try to download in full screen mode as host page is restricting the pdf download.");
            }
            el.remove();
          }
        }

        setTimeout(() => {
          let path = res?.path;
          window.open(`${API_URL}${path}`, "_blank");
        }, 2000);
        callBack(res);
      } else if (type === "fail") {
        failureMessagePopup('Error message', res?.message)
        callBack(null);
      }
    });
  };

  useEffect(() => {
    const videoParam = {
      videoElementID: "quix-video-editor",
      videoName: "edit-video",
      videoURL:`https://13.232.41.141/public/upload/videos/edit-video.mp4`,
      videoID: "sdfd",
      videoDuration: "00:05",
      videoDataJSON: JSON.stringify({}),
      userDetails: [],
    };

    new VideoEditor(videoParam, getEditedVideoDetails);
  }, []);

  return (
    <div>
      <div id="quix-video-editor"></div>
    </div>
  );
};

export default EditVideoModul;
