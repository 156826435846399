import React, { useState, useEffect } from "react";
import AdminLayout from "../../layout/adminLayout";
import trashIcon from "../../assets/images/trash-icon.png";
import editVideoIcon from "../../assets/images/video-icon.png";
import editImageIcon from "../../assets/images/image-icon.png";
import actionIcon from "../../assets/images/action-icon.png";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Table from "../../components/Table/Table";
import EditActionModal from "../../components/Modal/EditActionModal";
import {
    deleteScreenshotAdmin,
    getAllScreenshotsListAdmin,
    SearchScreenshotAdmin,
} from "../../../redux/actions/admin/screenshots";
import dummyUserIcon from "../../assets/images/dumy-user.jpg";
import MediaContentCard from "../../components/Cards/MediaContentCard";
import Filters from "../../components/Filters/Filters";
import FilterContent from "../../components/Filters/FilterContent";
import UserProfileCard from "../../components/Cards/UserProfileCard";
import VideoViewCard from "../../components/Cards/VideoViewCard";
import ScreenshotViewCard from "../../components/Cards/ScreenshotViewCard";
import { checkServerURL, sg_format_bytes } from "../../../helper/helper";
import {
    deleteVideoAdmin,
    getAllVideosListAdmin,
    SearchVideoAdmin,
} from "../../../redux/actions/admin/videos";
import { getStatisticsListAdmin } from "../../../redux/actions/admin/statistics";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { successMessageModal } from "../../components/Modal/MessagesModal";
import dateFormat from "dateformat";
import { confirmationModalMessage, csvScreenshotColumns, csvVideoColumns, screenshotCSV, videoCSV } from "./helper";

const ManageImageAndVideo = ({
    screenshotList,
    videoList,
    statistics,
    isScreenshotLoading,
    notFoundScreenshot,
    isVideoLoading,
    notFoundVideo,
}) => {
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showScreenshotModal, setShowScreenshotModal] = useState(false);
    const [showTableAction, setShowTableAction] = useState(false);
    const [showTableActionScreenshot, setShowTableActionScreenshot] =
        useState(false);
    const [selectedItemsIDs, setSelectedItemsIDs] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedVideoItemsIDs, setSelectedVideoItemsIDs] = useState([]);
    const [isAllSelectedVideo, setIsAllSelectedVideo] = useState([]);
    const [getScreenshotdata, setScreenshotData] = useState([]);
    const [getVideodata, setVideoData] = useState([]);
    const userID = localStorage.getItem(
        localStoragePrefix + "screenGeniusUserID"
    );
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    // get all screenshots
    const screenshotListData = screenshotList?.data ? screenshotList?.data : [];
    const videoListData = videoList?.data ? videoList?.data : [];
    const statisticsData = statistics?.data ? statistics?.data : [];
    useEffect(() => {
        if (userID) {
            dispatch(getAllScreenshotsListAdmin(Number(userID)));
            dispatch(getAllVideosListAdmin(Number(userID)));
            dispatch(getStatisticsListAdmin(Number(userID)));
        }
    }, [userID]);

    // media table screenshot columns
    const screenshotColumns = [
        {
            Header: (
                <input
                    className="form-check-input check-items"
                    type="checkbox"
                    onChange={(e) => handleMultiSelectItems(e, "screenshot")}
                    checked={isAllSelected ? true : false}
                />
            ),
            accessor: "checks",
            disableSortBy: true,
            Cell: (row) => (
                <>
                    <input
                        className="form-check-input check-items"
                        type="checkbox"
                        checked={
                            selectedItemsIDs?.includes(row?.row?.original?.id) ? true : false
                        }
                        onChange={(e) =>
                            handleChangeSelectedItems(e, row?.row?.original?.id, "screenshot")
                        }
                    />
                </>
            ),
        },
        { Header: "#", accessor: (row, i) => i + 1 },
        {
            Header: "User name",
            Cell: (row) => (
                <UserProfileCard
                    src={
                        !row?.row?.original?.userDetails?.picture ||
                            row?.row?.original?.userDetails?.picture === "null" ||
                            row?.row?.original?.userDetails?.picture === null
                            ? dummyUserIcon
                            : checkServerURL(
                                API_URL,
                                `${row?.row?.original?.userDetails?.picture}`
                            )
                    }
                    name={row?.row?.original?.userDetails?.name}
                />
            ),
        },
        {
            Header: "Content",
            accessor: "screenshot",
            Cell: (row) => (
                <>
                    <MediaContentCard
                        src={
                            !row?.row?.original?.thumbnail ||
                                row?.row?.original?.thumbnail === "null" ||
                                row?.row?.original?.thumbnail === null
                                ? dummyUserIcon
                                : checkServerURL(API_URL, `${row?.row?.original?.thumbnail}`)
                        }
                        title={row?.row?.original?.title}
                        duration={`${row?.row?.original?.file_size &&
                            sg_format_bytes(row?.row?.original?.file_size)
                            }- Screenshot`}
                    ></MediaContentCard>
                </>
            ),
        },
        {
            Header: "Date & time",
            Cell: (row) => (
                <>
                    {row?.row?.original?.created_at
                        ? dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")
                        : "N/A"}
                </>
            ),
        },
        {
            Header: "Action",
            Cell: (row) => (
                <div className="action-cell">
                    <div className="edit-action-cell">
                        <img className="action-img" src={actionIcon} />
                        <EditActionModal>
                            <div
                                className="column-action gap-2"
                                onClick={() => {
                                    setShowScreenshotModal(!showScreenshotModal);
                                    setScreenshotData(row?.row?.original);
                                }}
                            >
                                <img src={editImageIcon} />
                                <span>Screenshot details</span>
                            </div>
                            <div
                                className="column-action delete-action gap-2"
                                disabled={loading}
                                onClick={() => {
                                    setScreenshotData(row?.row?.original);
                                    modalAction("SCREENSHOT_DELETE", "screenshot");
                                }}
                            >
                                <img src={trashIcon} />
                                <span>Delete content</span>
                            </div>
                        </EditActionModal>
                    </div>
                </div>
            ),
        },
    ];

    // media table video columns
    const videoColumns = [
        {
            Header: (
                <input
                    className="form-check-input check-items"
                    type="checkbox"
                    onChange={(e) => handleMultiSelectItems(e, "video")}
                    checked={isAllSelectedVideo ? true : false}
                />
            ),
            accessor: "check",
            disableSortBy: true,
            Cell: (row) => (
                <>
                    <input
                        className="form-check-input check-items"
                        type="checkbox"
                        checked={
                            selectedVideoItemsIDs?.includes(row?.row?.original?.id)
                                ? true
                                : false
                        }
                        onChange={(e) =>
                            handleChangeSelectedItems(e, row?.row?.original?.id)
                        }
                    />
                </>
            ),
        },
        { Header: "#", accessor: (row, i) => i + 1 },
        {
            Header: "User name",
            Cell: (row) => (
                <UserProfileCard
                    src={
                        !row?.row?.original?.userDetails?.picture ||
                            row?.row?.original?.userDetails?.picture === "null" ||
                            row?.row?.original?.userDetails?.picture === null
                            ? dummyUserIcon
                            : checkServerURL(
                                API_URL,
                                `${row?.row?.original?.userDetails?.picture}`
                            )
                    }
                    name={row?.row?.original?.userDetails?.name}
                />
            ),
        },
        {
            Header: "Content",
            accessor: "video",
            Cell: (row) => (
                <>
                    <MediaContentCard
                        src={
                            !row?.row?.original?.thumbnail_mod ||
                                row?.row?.original?.thumbnail_mod === "null" ||
                                row?.row?.original?.thumbnail_mod === null
                                ? dummyUserIcon
                                : checkServerURL(API_URL, `${row?.row?.original?.thumbnail_mod}`)
                        }
                        title={row?.row?.original?.title}
                        duration={`${row?.row?.original?.file_duration_mod} min`}
                    ></MediaContentCard>
                </>
            ),
        },
        {
            Header: "Date & time",
            Cell: (row) => (
                <>
                    {row?.row?.original?.created_at
                        ? dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")
                        : "N/A"}
                </>
            ),
        },
        {
            Header: "Action",
            Cell: (row) => (
                <div className="action-cell">
                    <div className="edit-action-cell">
                        <img className="action-img" src={actionIcon} />
                        <EditActionModal>
                            <div
                                className="column-action gap-2"
                                onClick={() => {
                                    setShowVideoModal(!showVideoModal);
                                    setVideoData(row?.row?.original);
                                }}
                            >
                                <img src={editVideoIcon} />
                                <span>Video details</span>
                            </div>
                            <div
                                className="column-action delete-action gap-2"
                                disabled={loading}
                                onClick={() => {
                                    modalAction("VIDEO_DELETE", "video");
                                    setVideoData(row?.row?.original);
                                }}
                            >
                                <img src={trashIcon} />
                                <span>Delete content</span>
                            </div>
                        </EditActionModal>
                    </div>
                </div>
            ),
        },
    ];

    const [showItemData, setShowItemData] = useState([]);
    const visibleItems = showItemData && showItemData;
    // for videos
    const [showVideoItemData, setShowVideoItemData] = useState([]);
    const visibleVideoItems = showVideoItemData && showVideoItemData;
    // handle multi select screenshot
    const handleMultiSelectItems = (e, type) => {
        let checked = e.target.checked;
        if (type === "screenshot") {
            if (checked) {
                let newSelectedIDs = visibleItems.map((user) => user.id);
                setSelectedItemsIDs((prevIDs) => [
                    ...new Set([...prevIDs, ...newSelectedIDs]),
                ]);
            } else {
                setSelectedItemsIDs([]);
            }
        } else {
            if (checked) {
                let newSelectedIDs = visibleVideoItems.map((user) => user.id);
                setSelectedVideoItemsIDs((prevIDs) => [
                    ...new Set([...prevIDs, ...newSelectedIDs]),
                ]);
            } else {
                setSelectedVideoItemsIDs([]);
            }
        }
    };

    // handle single select screenshot
    const handleChangeSelectedItems = (e, id, type) => {
        const checked = e.target.checked;
        if (type === "screenshot") {
            setSelectedItemsIDs((prevSelectedItemsIDs) => {
                if (checked) {
                    if (!prevSelectedItemsIDs.includes(id)) {
                        return [...prevSelectedItemsIDs, id];
                    }
                } else {
                    return prevSelectedItemsIDs.filter((item) => item !== id);
                }
                return prevSelectedItemsIDs;
            });
        } else {
            setSelectedVideoItemsIDs((prevSelectedItemsIDs) => {
                if (checked) {
                    if (!prevSelectedItemsIDs.includes(id)) {
                        return [...prevSelectedItemsIDs, id];
                    }
                } else {
                    return prevSelectedItemsIDs.filter((item) => item !== id);
                }
                return prevSelectedItemsIDs;
            });
        }
    };

    useEffect(() => {
        if (selectedItemsIDs) {
            setIsAllSelected(
                visibleItems.length > 0 &&
                visibleItems.every((user) => selectedItemsIDs?.includes(user?.id))
            );
            if (selectedItemsIDs?.length > 0) {
                setShowTableActionScreenshot(true);
            } else {
                setShowTableActionScreenshot(false);
            }
        }
        if (selectedVideoItemsIDs) {
            setIsAllSelectedVideo(
                visibleVideoItems.length > 0 &&
                visibleVideoItems.every((user) =>
                    selectedVideoItemsIDs.includes(user.id)
                )
            );
            if (selectedVideoItemsIDs?.length > 0) {
                setShowTableAction(true);
            } else {
                setShowTableAction(false);
            }
        }
    }, [
        selectedItemsIDs,
        visibleItems,
        selectedVideoItemsIDs,
        visibleVideoItems,
    ]);

    // handle search screenshot
    const handleSearchScreenshot = (text) => {
        const payload = {
            admin_id: Number(userID),
            text: text,
        };
        dispatch(SearchScreenshotAdmin(payload));
    };

    // handle search video
    const handleSearchVideo = (text) => {
        const payload = {
            admin_id: Number(userID),
            text: text,
        };
        dispatch(SearchVideoAdmin(payload));
    };

    // reset searched video and screenshot
    const resetScreenshotAndVideo = (type) => {
        if (type === "screenshot") {
            dispatch(getAllScreenshotsListAdmin(Number(userID)));
        } else {
            dispatch(getAllVideosListAdmin(Number(userID)));
        }
    };

    // handle screenshot csv data
    let screenshotDataCSV = screenshotCSV(screenshotListData);
    // handle video csv data
    let videoDataCSV = videoCSV(videoListData);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        action: "",
        title: "",
        message: "",
        type: "",
        icon: null,
    });
    const modalAction = (action, type) => {
        const modalContent = confirmationModalMessage({
            action: action,
            type: type,
        });
        if (modalContent) {
            setShowConfirmModal(true);
            setModalContent(modalContent);
        }
    };

    const callBackAction = (res, type, tab) => {
        if (res) {
            switch (type) {
                case "SCREENSHOT_DELETE":
                    return handleDeleteScreenshot({ id: [getScreenshotdata?.id] });
                case "MULTI_SCREENSHOT_DELETE":
                    return handleDeleteScreenshot({ id: selectedItemsIDs });
                case "VIDEO_DELETE":
                    return handleDeleteVideo({ id: [getVideodata?.id] });
                case "MULTI_VIDEO_DELETE":
                    return handleDeleteVideo({ id: selectedVideoItemsIDs });
                default:
                    return null;
            }
        }
    };

    const handleDeleteScreenshot = (res) => {
        if (res) {
            setSelectedItemsIDs([]);
            setShowConfirmModal(false);
            const payload = {
                admin_id: Number(userID),
                id: res?.id,
            };
            dispatch(
                deleteScreenshotAdmin(payload, (type, res) => {
                    if (type === "success") {
                        successMessageModal(
                            "Successful Deleted screenshot.",
                            "Screenshot will permanently delete successfully."
                        );
                        setLoading(false);
                        resetScreenshotAndVideo("screenshot");
                        dispatch(getStatisticsListAdmin(Number(userID)));
                        setSelectedItemsIDs([]);
                    } else if (type === "fail") {
                        setLoading(false);
                    }
                })
            );
        }
    };

    const handleDeleteVideo = (res) => {
        if (res) {
            setSelectedVideoItemsIDs([]);
            setShowConfirmModal(false);
            const payload = {
                admin_id: Number(userID),
                id: res?.id,
            };
            dispatch(
                deleteVideoAdmin(payload, (type, res) => {
                    if (type === "success") {
                        successMessageModal(
                            "Successful Deleted video.",
                            "video will permanently delete successfully."
                        );
                        resetScreenshotAndVideo("video");
                        dispatch(getStatisticsListAdmin(Number(userID)));
                        setSelectedVideoItemsIDs([]);
                        setLoading(false);
                    } else if (type === "fail") {
                        setLoading(false);
                    }
                })
            );
        }
    };

    return (
        <div className="manage-media-screenshot-and-video">
            <AdminLayout pageTitle={"manage-image-and-video"}>
                <div className="screenshot-and-video-content">
                    <div className="table-management-section">
                        <div className="management-header">
                            <h4>Image & Video Management</h4>
                            <p>
                                You can manage inappropriate content of for other reasons video
                                and screenshots.
                            </p>
                        </div>
                        <Filters
                            filterList={[
                                {
                                    id: "all-screenshot",
                                    className: "active",
                                    title: "All Screenshots",
                                },
                                {
                                    id: "all-video",
                                    className: "",
                                    title: "All Videos",
                                },
                            ]}
                        />
                    </div>
                    <div className="table-statistics">
                        <div className="statistics-header d-flex justify-content-between align-items-center">
                            <h5>Statistics</h5>
                            {/* <Link className="nav-link" to={"/admin/dashboard"}>
                                View video & screenshots reports
                            </Link> */}
                        </div>
                        <div className="statistics-content">
                            <div className="statistics-card d-flex align-items-center">
                                <h6>{`${statisticsData?.total_user}`}</h6>
                                <span>Total Users</span>
                            </div>
                            <div className="statistics-card d-flex align-items-center">
                                <h6>{`${statisticsData?.total_video}`}</h6>
                                <span>Total Videos</span>
                            </div>
                            <div className="statistics-card d-flex align-items-center">
                                <h6>{`${statisticsData?.total_screenshots}`}</h6>
                                <span>Total Screenshots</span>
                            </div>
                        </div>
                    </div>
                    <FilterContent
                        filterContent={[
                            {
                                id: "all-screenshot",
                                className: "show active screenshot-tab",
                                childrenData: (
                                    <Table
                                        csvColumns={csvScreenshotColumns}
                                        tableData={screenshotListData}
                                        tableColumns={screenshotColumns}
                                        isLoading={isScreenshotLoading}
                                        notFound={notFoundScreenshot}
                                        csvData={screenshotDataCSV}
                                        setShowItems={(e) => setShowItemData(e)}
                                        searchCallBack={(e) => handleSearchScreenshot(e)}
                                        resetCallBack={() => resetScreenshotAndVideo("screenshot")}
                                        searchBar={true}
                                        tableType={"screenshot"}
                                        showAction={showTableActionScreenshot}
                                        actionBtn={
                                            <>
                                                <button
                                                    className="btn delete-btn"
                                                    onClick={() => {
                                                        modalAction(
                                                            "MULTI_SCREENSHOT_DELETE",
                                                            "screenshot"
                                                        );
                                                    }}
                                                >
                                                    <img src={trashIcon} />
                                                    Delete
                                                </button>
                                            </>
                                        }
                                    />
                                ),
                            },
                            {
                                id: "all-video",
                                className: "all-video-tab",
                                childrenData: (
                                    <Table
                                        csvColumns={csvVideoColumns}
                                        tableData={videoListData}
                                        tableColumns={videoColumns}
                                        csvData={videoDataCSV}
                                        isLoading={isVideoLoading}
                                        notFound={notFoundVideo}
                                        setShowItems={(e) => setShowVideoItemData(e)}
                                        searchCallBack={(e) => handleSearchVideo(e)}
                                        resetCallBack={() => resetScreenshotAndVideo("video")}
                                        searchBar={true}
                                        tableType={"video"}
                                        showAction={showTableAction}
                                        actionBtn={
                                            <>
                                                <button
                                                    className="btn delete-btn"
                                                    onClick={() => {
                                                        modalAction("MULTI_VIDEO_DELETE", "video");
                                                    }}
                                                >
                                                    <img src={trashIcon} />
                                                    Delete
                                                </button>
                                            </>
                                        }
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
                {showVideoModal && (
                    <VideoViewCard
                        closeModal={setShowVideoModal}
                        cardData={getVideodata}
                    />
                )}
                {showScreenshotModal && (
                    <ScreenshotViewCard
                        closeModal={setShowScreenshotModal}
                        cardData={getScreenshotdata}
                    />
                )}
                {showConfirmModal && (
                    <ConfirmationModal
                        isShowModal={showConfirmModal}
                        closeModal={setShowConfirmModal}
                        callBack={(res) =>
                            callBackAction(res, modalContent?.type, modalContent?.tab)
                        }
                        isLoading={false}
                        isDisabled={false}
                        icon={modalContent?.icon}
                        btnText={"Continue"}
                        messageTitle={modalContent?.title}
                        description={modalContent?.message}
                    />
                )}
            </AdminLayout>
        </div>
    );
};

const mapStateToProps = (state) => ({
    screenshotList: state?.admin_screenshots?.list,
    isScreenshotLoading: state?.admin_screenshots?.is_loading,
    notFoundScreenshot: state?.admin_screenshots?.not_found,
    videoList: state?.admin_videos?.list,
    isVideoLoading: state?.admin_videos?.is_loading,
    notFoundVideo: state?.admin_videos?.not_found,
    statistics: state?.admin_statistics?.list,
});

export default connect(mapStateToProps, { getAllScreenshotsListAdmin })(
    ManageImageAndVideo
);
