import React from "react";
import logoImg from "../../../assets/images/home/ocr-logo.png";
import slideImg1 from "../../../assets/images/home/ocr-hm-slider.png";
import chromeIcon from "../../../assets/images/home/chrome-icon.png";
import { Link } from "react-router-dom";
import { localStoragePrefix } from "../../../utils/config";

const HeaderSection = () => {
  const isLoginIn = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className="header_section">
      <div className="container top_container">
        {/* Navbar */}
        <header className="ScreenGenius-header">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <Link className="navbar-brand" to="#">
                <img src={logoImg} height="80" alt="CoolBrand" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav">
                <Link className="nav-item nav-link active" to="/" onClick={()=>handleScrollToTop()}>
                  Home
                </Link>
                <a className="nav-item nav-link" href="#screenshot-tab">
                  Capture
                </a>
                <a className="nav-item nav-link" href="#screen-recording-tab">
                  Recorder
                </a>
                <a className="nav-item nav-link" href="#pricing-plans-tab">
                  Pricing
                </a>
                <a
                  className="nav-item nav-link"
                  href="#frequently-asked-questions"
                >
                  FAQ
                </a>
              </div>
              <div className="navbar-nav ms-auto">
                {isLoginIn !== null ? (
                  <Link className="nav-item nav-link login" to="/dashboard">
                    Dashboard
                  </Link>
                ) : (
                  <a className="nav-item nav-link login" href="/login">
                    Login
                  </a>
                )}
                <Link
                  className="nav-item nav-link get_started"
                  to="https://chromewebstore.google.com/detail/capios-ocr-screenshot-vid/dmfjonjeonmaoehpjaonkjgfdjanapbe"
                  target="_blank"
                >
                  Get started
                </Link>
              </div>
            </div>
          </nav>
        </header>
        {/* crousel section */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center -mid">
              <div className="left-content">
                <h2 className="section-heading">Capture your <br /> screen <span className="chrome-badge">like a pro!</span></h2>
                <p>
                  Say Goodbye to bland Screen Captures! Take screenshots, record screen & effortlessly share with a dash of your awesome personality.
                </p>
                <Link
                  className="btn btn-primary chrome-thisbtn"
                  to="https://chromewebstore.google.com/detail/capios-ocr-screenshot-vid/dmfjonjeonmaoehpjaonkjgfdjanapbe"
                >
                  <img className="chrome-icon" src={chromeIcon} />
                  Add to Chrome
                  <span>It’s free!</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center ScreenGenius-mid">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    className="d-block w-100"
                    src={slideImg1}
                    alt="First slide"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
