import React, { useEffect, useRef } from "react";
import logoImg from "../../assets/images/home/ocr-logo.png";
import activityBanner from "../../assets/images/home/ocr-activity-bnnr.png";
import firstActStepImg1 from "../../assets/images/home/ocr-tnx-activity-first-step1.png";
import firstActStepImg2 from "../../assets/images/home/ocr-tnx-activity-first-step2.png";
import firstActStepImg3 from "../../assets/images/home/ocr-tnx-activity-first-step3.png";
import secondActStepImg1 from "../../assets/images/home/ocr-tnx-activity-sec-step1.png";
import secondActStepImg2 from "../../assets/images/home/ocr-tnx-activity-sec-step2.png";
import secondActStepImg3 from "../../assets/images/home/ocr-tnx-activity-sec-step3.png";
import thirdActStepImg1 from "../../assets/images/home/ocr-tnx-activity-third-step1.png";
import thirdActStepImg2 from "../../assets/images/home/ocr-tnx-activity-third-step2.png";
import thirdActStepImg3 from "../../assets/images/home/ocr-tnx-activity-third-step3.png";
import fourthActStepImg1 from "../../assets/images/home/ocr-tnx-activity-fourth-step1.png";
import fourthActStepImg2 from "../../assets/images/home/ocr-tnx-activity-fourth-step2.png";
import fourthActStepImg3 from "../../assets/images/home/ocr-tnx-activity-fourth-step3.png";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import Slider from "../../components/Slider/Slider";
// import NewFeaturePopup from "./components/NewFeaturePopup";
import AdSenseAd from "../../components/Adsense/adSenseAd";

const ThankYou = () => {
  const slidersRefs = useRef([]);
  // const [showVizamModal, setShowVizamModal] = useState(true);
  useEffect(() => {
    let elementList = document.querySelectorAll('.activity-slider');
    if (elementList) {
      slidersRefs?.current?.forEach((current) => {
        const idName = current?.getAttribute('id');
        new window.bootstrap.Carousel(`#${idName}`, {
          interval: 2000,
          ride: 'carousel',
          pause: 'hover',
        });
      })
    };
    // setShowVizamModal(true);
  }, []);

  // google-adsense
  let formatOfAds = 'horizontal';
  let display = window.innerWidth <= 480 ? 'none' : 'block';
  let showAds = window.innerWidth <= 480 ? false : true;
  let adsStyle = { display: display, maxWidth: '100%', maxHeight: '120px', height: '120px', width: '100%' };

  return (
    <div className="thankyou-page" id="root">
      {/* {showVizamModal && (<NewFeaturePopup isShowModal={showVizamModal} closeModal={setShowVizamModal} />)} */}
      <div className="header_section">
        <div className="container top_container">
          <header className="ScreenGenius-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid justify-content-center">
                <Link className="navbar-brand" to="/">
                  <img src={logoImg} height="80" alt="CoolBrand" />
                </Link>
              </div>
            </nav>
          </header>
        </div>
      </div>
      <div className="thanks-page-content mt-5">
        <div className="activity-banner col-sm-6 mx-auto pt-3">
          <div className="activity-bnnr-innr emoji">
            <img className="activity-bnnr-img" src={activityBanner} />
          </div>
          <div className="activity-banner-heading">
            <div className="section_title">
              <h2><span className="heading-badge-text">Thank You!</span> For choosing Capios</h2>
              <p>
                Buckle up! Your journey to create stunning masterpieces begins now
              </p>
            </div>
          </div>
        </div>
        <div className="activity-steps">
          <div className="activity-steps-column activity-steps-1">
            <div className="col-sm-8 mx-auto activity-steps-column-inner">
              <div className="section_title">
                <h2>To easily access Capios.<br />
                  <span className="heading-badge-text"> Do this first.</span></h2>
                <p>
                  Follow the instructions for a seamless screenshotting experience!
                </p>
              </div>
              {/* Activity step slider one*/}
              <Slider sliderID={'activity-steps-1'} ref={(el) => slidersRefs.current?.push(el)} extraClass={'activity-slider'}>
                <div className="carousel-item active" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>01</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>Access Capios from Extensions menu.</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={firstActStepImg1} />
                    </div>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>02</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>Pin it to the bar.</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={firstActStepImg2} />
                    </div>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>03</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>Start with selecting Screenshot or Screen recording mode.</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={firstActStepImg3} />
                    </div>
                  </div>
                </div>
              </Slider>
              {/* Activity step slider one end*/}
            </div>
          </div>
          <div className="activity-steps-column activity-steps-2">
            <div className="col-sm-8 mx-auto activity-steps-column-inner">
              <div className="section_title">
                <h2>Ready for <span className="heading-badge-text"> your first</span> <br /> Capios <span className="heading-badge-text">screenshot?</span></h2>
                <p>
                  Here’s how you take your first capture.
                </p>
              </div>
              {/* Activity step slider two*/}
              <Slider sliderID={'activity-steps-2'} ref={(el) => slidersRefs.current?.push(el)} extraClass={'activity-slider'}>
                <div className="carousel-item active" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>01</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>To take your first amazing screenshot, simply select the area you want to capture & Capios will do the rest.</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={secondActStepImg1} />
                    </div>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>02</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>Now, edit it or annotate on it, to give your personalized touch.</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={secondActStepImg2} />
                    </div>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>03</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>And now, it’s ready to share via a shareable link or through an email.</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={secondActStepImg3} />
                    </div>
                  </div>
                </div>
              </Slider>
              {/* Activity step slider two end*/}
            </div>
          </div>
          <div className="activity-steps-column activity-steps-3">
            <div className="col-sm-8 mx-auto activity-steps-column-inner">
              <div className="section_title">
                <h2>Screen<span className="heading-badge-text"> recording made easy</span> <br /> with Capios.</h2>
                <p>
                  Here’s how you take your first capture.
                </p>
              </div>
              {/* Activity step slider three*/}
              <Slider sliderID={'activity-steps-3'} ref={(el) => slidersRefs.current?.push(el)} extraClass={'activity-slider'}>
                <div className="carousel-item active" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>01</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>Do your first screen recording by selecting your current window, or select all windows, your current tab or your camera.</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={thirdActStepImg1} />
                    </div>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>02</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>Now, add text, effects, music and trim as you edit, with your creative genius hat on!</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={thirdActStepImg2} />
                    </div>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>03</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>Now it’s ready to share via a sharing link with your friends. You can also upload the screen recordings on to the cloud easily.</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={thirdActStepImg3} />
                    </div>
                  </div>
                </div>
              </Slider>
              {/* Activity step slider three end*/}
            </div>
          </div>
          <div className="activity-steps-column activity-steps-4">
            <div className="col-sm-8 mx-auto activity-steps-column-inner">
              <div className="section_title">
                <h2>Unlock Text from Images with the <span className="heading-badge-text">OCR Tool</span></h2>
                <p>
                  Here’s how you take your first capture.
                </p>
              </div>
              {/* Activity step slider two*/}
              <Slider sliderID={'activity-steps-4'} ref={(el) => slidersRefs.current?.push(el)} extraClass={'activity-slider'}>
                <div className="carousel-item active" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>01</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={fourthActStepImg1} />
                    </div>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>02</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={fourthActStepImg2} />
                    </div>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <div className="activity-steps-card d-flex">
                    <div className="activity-steps-content col-lg-4">
                      <div className="step-counts">
                        <h4>03</h4>
                        <span>Step</span>
                      </div>
                      <div className="step-discription">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                      </div>
                    </div>
                    <div className="activity-steps-banner col-lg-8">
                      <img src={fourthActStepImg3} />
                    </div>
                  </div>
                </div>
              </Slider>
              {/* Activity step slider two end*/}
            </div>
          </div>
        </div>
        {showAds && (
          <div className="ads-sense mb-2">
            <AdSenseAd
              client="ca-pub-4424794709901498"
              slot="3435754143"
              format={formatOfAds}
              style={adsStyle}
              responsive={true}
            />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ThankYou;
