import React from 'react';
import { Link } from 'react-router-dom';
import logoIcon from "../../assets/images/logo.png";
import menuIcon from "../../assets/images/menu-icon.svg";

const ResposiveNavbar = ({ showMenu, setShowMenu }) => {
    return (
        <div className='resposive-admin-navbar d-flex align-items-center justify-content-between'>
            <div className="quix-admin-logo">
                <Link to={"/dashboard"}>
                    <img src={logoIcon} />
                </Link>
            </div>
            <div className='admin-menu-toggler' onClick={() => setShowMenu(!showMenu)}>
                <img src={menuIcon} className='w-100 h-100' />
            </div>
        </div>
    );
};

export default ResposiveNavbar;