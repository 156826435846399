import React, { useEffect, useState } from "react";
import PricingModal from "../PricingPlan/PricingModal";
import arrowIcon from "../../../assets/images/ocr-arrow-down.png";
import jQuery from "jquery";
import UpgradeModal from "../UpgradeModal";

const AvailableStorageBar = ({
  typeOfItems,
  addedItems,
  progress,
  showModal,
  closeModal,
  limitUploaded
}) => {
  const [itemsOBJ, setItemsOBJ] = useState({
    type: "",
    addedItems: "",
    totalItems: "",
    progress: 0,
  });
  const [showUpgradeModal, setUpgradeModal] = useState(false);
  // Updates the storage details based on the type of items (images or videos).
  const getStorageDetails = (typeOfItems, addedItems, progress) => {
    switch (typeOfItems) {
      case "Image":
        setItemsOBJ({
          type: "images",
          addedItems: addedItems,
          totalItems: limitUploaded === -1 ? "Unlimited" : limitUploaded,
          progress: progress?.screenshot_progress,
        });
        return;
      case "Video":
        setItemsOBJ({
          type: "videos",
          addedItems: addedItems,
          totalItems: limitUploaded === -1 ? "Unlimited" : limitUploaded,
          progress: progress?.videos_progress,
        });
        return;
      default:
        return setItemsOBJ({ type: "", addedItems: 0, totalItems: 0 });
    }
  };
  const [showPricingPlanPopup, setShowPricingPlanPopup] = useState(false);
  useEffect(() => {
    getStorageDetails(typeOfItems, addedItems, progress);
  }, [typeOfItems, addedItems, progress]);

  const storagePersentage = itemsOBJ?.progress ? itemsOBJ?.progress : 0;

  const handleResizestorage = () => {
    jQuery(".quix-available-storage-card").toggleClass("hide-upgrade");
  };

  return (
    <div
      className="quix-available-storage-card hide-upgrade"
    // style={{
    //   display: !showModal ? "none" : "block",
    // }}
    >
      {showPricingPlanPopup && (
        <PricingModal
          showModal={showPricingPlanPopup}
          closeModal={setShowPricingPlanPopup}
        />
      )}
      <span className="quix-storage-card-heading">Available Storage</span>
      <div className="quix-storage-card-description">
        <p>
          You have used upto {storagePersentage}% of the storage available.
          Please upgrade to get more data storage.
        </p>
        {limitUploaded !== -1 && (
          <button
            className="quix-storage-card-upgrade-btn"
            onClick={()=>setUpgradeModal(!showUpgradeModal)}
            title="Coming Soon"
          >
            Upgrade
          </button>
        )}

      </div>
      <div className="quix-storage-card-progress">
        <div className="quix-storage-progress-outer">
          <div
            className="quix-storage-progress"
            style={{ width: `${itemsOBJ?.progress}%` }}
          ></div>
        </div>
        <p className="quix-storage-detail">
          <span className="added-items">
            {itemsOBJ?.addedItems ? itemsOBJ?.addedItems : 0}
          </span>
          /<span className="quix-total-items">{itemsOBJ?.totalItems}</span>
          {itemsOBJ?.type}
        </p>
      </div>
      <div className="resize-storage">
        <div className="resize-btn" onClick={() => handleResizestorage()}>
          <img
            src={arrowIcon}
            id="storage-size"
            className={"size-storage-bar"}
          />
        </div>
      </div>
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          closeModal={setUpgradeModal}
        />
      )}
    </div>
  );
};

export default AvailableStorageBar;
