import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/images/quix-close.png";
import { localStoragePrefix, WEB_URL } from "../../utils/config";
import { connect } from "react-redux";
import { failureMessagePopup } from "../../helper/helper";
import loaderIcon from "../../assets/images/light-loader.gif";

const ShareLink = ({ showModal, closeModal, itemID, itemUID, cardType }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!itemID) {
      failureMessagePopup("Error Message", "Items doest not found!");
      return;
    }
  }, [itemID]);

  const itemId = Number(itemID) ? Number(itemID) : null;
  const itemUid = itemUID && itemUID;
  const uploadType = cardType ? cardType : "";
  const tokenAuth = localStorage.getItem(
    localStoragePrefix + "screenGeniusAuthToken"
  );

  return (
    <>
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !showModal ? "none" : "block",
        }}
      ></div>
      <div id="quix-dashboard-popup">
        <div
          className="quix-dashboard-popup-inner pb-0"
          style={{ height: "410px", background: "rgba(43, 42, 42, 0.78)" }}
        >
          <div className="outer-share-media">
            {loading && (
              <div
                className="share-loading"
                style={{ display: loading ? `block` : "none" }}
              >
                <img src={loaderIcon} alt="loading..." />
              </div>
            )}
            <iframe
              src={`${WEB_URL}/share/${itemUid}/${uploadType}?token=${tokenAuth}`}
              onLoad={() => setLoading(false)}
              allow="clipboard-read; clipboard-write; web-share;"
              style={{ height: "100%", width: "100%" }}
            ></iframe>
          </div>
          <div
            className="quix-dashboard-close"
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  googleToken: state?.auth?.google_token,
});

export default connect(mapStateToProps, {})(ShareLink);
