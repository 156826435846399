import {
    LOADING,
    NOT_FOUND_PRICINGPLAN,
    GET_ALL_PRICINGPLAN,
    GET_PAYMENT,
  } from "../actions/type";
  
  const INITIAL_STATE = {
    list: [],
    is_loading: false,
    not_found: false,
    payment:[]
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case LOADING:
        return { ...state, is_loading: true };
      case GET_ALL_PRICINGPLAN:
        return { ...state, list: action.payload, is_loading: false };
      case GET_PAYMENT:
        return { ...state, payment: action.payload, is_loading: false };
      case NOT_FOUND_PRICINGPLAN:
        return { ...state, not_found: true, is_loading: false };
      default:
        return state;
    }
  };
  