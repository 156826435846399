import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  manageEditVideo,
  manageEditVideoDetails
} from "../../redux/actions/manageVideos";
import { VideoEditor } from "./editor";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL, localStoragePrefix } from "../../utils/config";
import { checkAvailableVideoPath, checkServerURL, failureMessagePopup, successMessagePopup } from "../../helper/helper";
import { getUserDetailsByID } from "../../redux/actions/authentication";
import LoadingPage from "../loading-page/LoadingPage";

const EditVideo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const userID = localStorage.getItem(localStoragePrefix + "screenGeniusUserID");
  const accessToken = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");
  const editorInitializedRef = useRef(false);
  const [editorInitialized, setEditorInitialized] = useState(true);

  // *******************************
  const fetchVideoDetails = (payload) => {
    return Promise.all([
      new Promise((resolve, reject) => {
        dispatch(manageEditVideoDetails(payload, (type, result) => {
          if (type === 'success') {
            resolve(result);
          } else if (type === 'failed') {
            reject('manageVideoDetails failed');
            if(result?.response?.status === 404){
              failureMessagePopup('Error Message', result?.response?.data?.message);
            }else{
              failureMessagePopup('Error Message', 'Something Went wrong!')
            }
            setTimeout(() => { navigator('/dashboard') }, 2000);
          }
        }));
      }),
      new Promise((resolve, reject) => {
        dispatch(getUserDetailsByID(payload?.access_token, (result, type) => {
          if (type === 'success') {
            resolve(result);
          } else if (type === 'fail') {
            reject('getUserDetailsByID failed');
            failureMessagePopup('Error Message', 'Something Went wrong!')
            setTimeout(() => { navigator('/dashboard') }, 2000);
          }
        }));
      })
    ]);
  }

  useEffect(() => {
    if (id && userID && accessToken && !editorInitializedRef.current) {
      const payload = {
        id: id,
        user_id: userID,
        access_token: accessToken
      }
      fetchVideoDetails(payload)
        .then(([videoDetails, userDetails]) => {
          const videDetailObj = videoDetails && videoDetails;
          const videoUrl = checkServerURL(API_URL, checkAvailableVideoPath(videDetailObj?.path, videDetailObj?.path_mp4, null));
          const videoName = videDetailObj?.name && videDetailObj?.name;
          const videoID = videDetailObj?.id && videDetailObj?.id;
          const videoDuration = videDetailObj?.file_duration && videDetailObj?.file_duration;
          const videoDataJSON = videDetailObj?.json_data && videDetailObj?.json_data;

          const videoParam = {
            videoElementID: "quix-video-editor",
            videoName: videoName,
            videoURL: videoUrl,
            videoID: videoID,
            videoDuration: videoDuration,
            videoDataJSON: videoDataJSON,
            userDetails: userDetails?.data && userDetails?.data,
          };

          if (!editorInitializedRef.current) {
            new VideoEditor(videoParam, getEditedVideoDetails);
            editorInitializedRef.current = true;
            setEditorInitialized(false);
          }
        })
        .catch((error) => {
          console.error('An error occurred:', error);
        });
    }
  }, [id, userID, accessToken]);

  // *******************************
  const getEditedVideoDetails = (data, callBack) => {
    const payload = {
      id: data?.videoID,
      access_token: accessToken,
      user_id: Number(userID),
      ...data,
    };
    dispatch(
      manageEditVideo(payload, (type, res) => {
        if (type === "success") {
          successMessagePopup("Success!", "Video Edited successfully.");
          if (res.gifPaths && res.gifPaths.length > 0) {
            for (var i = 0; i < res.gifPaths.length; i++) {
              let el = document.createElement("a");
              el.setAttribute("href", checkServerURL(API_URL, res.gifPaths[i]));
              el.setAttribute("download", 'download');
              el.setAttribute("target", '_blank');
              document.body.appendChild(el);
              try {
                el.click();
              }
              catch (err) {
                alert(err + "Please try to download in full screen mode as host page is restricting the pdf download.");
              }
              el.remove();
            }
          }

          setTimeout(() => {
            navigator("/dashboard#videos");
          }, 2000);
          callBack(res);
        } else if (type === "fail") {
          failureMessagePopup('Error message', res?.message)
          callBack(null);
        }
      })
    );
  };

  return (
    <div>
      {editorInitialized && (
        <div>
          <LoadingPage opacity={'0.9'} />
        </div>
      )}
      <div id="quix-video-editor"></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  videoDetails: state?.videos?.edited_video,
  userDetails: state?.auth?.user_details
});

export default connect(mapStateToProps, { manageEditVideoDetails, getUserDetailsByID })(EditVideo);